import React from 'react';

import TopHeader from 'components/header/TopHeader';
import { isAuthorized, logOut } from 'util/authService';

const PrivateRoute = (props: { component: React.ReactElement }) => {
  if (!isAuthorized()) {
    logOut();
    return null;
  }

  return (
    <>
      <TopHeader />
      <div className="content">{props.component}</div>
    </>
  );
};

export default PrivateRoute;

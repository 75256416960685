import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';

import MultipleSelect from 'components/select/MultipleSelect';
import Modal from 'components/modal/Modal';
import { EAcceptanceCheckNames, EAnswers, EFileTypes, EStatuses, EWorkflowModules } from 'enums';
import { IRootState } from 'store/types';
import { IOptionType } from 'types';
import { ReactComponent as Info } from 'images/info.svg';
import ToolTip from 'components/toolTipField/ToolTipField';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import { FaceVerificationRejectionReasons } from 'constants/Reasons';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { formatRejectionReasons } from 'helper';
import { hasActiveAcceptanceCheck } from 'pages/identityReview/helper';
const statusMessages = {
  [EStatuses.pass]: 'identity.fv.result.label.pass',
  [EStatuses.fail]: 'identity.fv.result.label.fail',
}

const FaceVerification = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { reviewResult } = state;
  const { transactionData, transactionCode, manualCheckResult } = reviewResult;
  const { acceptanceChecks, rejectionReasons, identityReview } = manualCheckResult;
  const { faceLiveness } = transactionData || {};
  const { faceVerification } = faceLiveness || {};
  const result = EStatuses[faceVerification?.result as keyof typeof EStatuses] || EStatuses.pass;
  const faceVerificationReasons = formatRejectionReasons(
    FaceVerificationRejectionReasons, rejectionReasons.faceVerification, t,
  );
  const pageEnabled = transactionData?.modules.includes(EWorkflowModules.faceVerification);
  const [ imageType, setImageType ] = useState('');
  const [images, setImages] = useState({
    faceImage: '',
    faceAvatar: '',
  });

  const rejectionReasonsProps = {
    value: faceVerificationReasons,
    handler: (selected: IOptionType[]) => dispatch(reviewResultActions.setRejectionReasons({
      ...rejectionReasons,
      faceVerification: selected?.map((reason: IOptionType) => reason.value) || []
    })),
    placeholder: t('identity.fv.rejection-reason-placeholder'),
    options: FaceVerificationRejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(reviewResultActions.setFaceVerificationReview({
      result: event.target.value,
    }));
    if (event.target.value === EAnswers.yes) {
      dispatch(reviewResultActions.setRejectionReasons({
        ...rejectionReasons,
        faceVerification: [],
      }));
    }
  }

  const acceptances = [
    {
      name: EAcceptanceCheckNames.faceVerificationPhysicalSigns,
      flag: acceptanceChecks.faceVerificationPhysicalSigns,
      translationKeys: {
        heading: 'identity.fv.guideline.message1.tooltip',
        listItems: [
          'identity.fv.guideline.sub-message1.1.tooltip',
          'identity.fv.guideline.sub-message1.2.tooltip',
          'identity.fv.guideline.sub-message1.3.tooltip',
          'identity.fv.guideline.sub-message1.4.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.faceVerificationSamePerson,
      name: EAcceptanceCheckNames.faceVerificationSamePerson,
      translationKeys: {
        heading: 'identity.fv.guideline.message2.tooltip',
        listItems: [
          'identity.fv.guideline.sub-message2.1.tooltip',
          'identity.fv.guideline.sub-message2.2.tooltip',
        ]
      },
    }
  ];

  return (
    <div className="face-verification-container">
      <p className="face-verification-title">{t('identity.fv.heading')}</p>
      <div className='face-verification-main'>
        <div className="face-verification-section">
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={pageEnabled ? EFileTypes.faceAvatar : ''}
            path={location.pathname}
            setImageType={(imageType) => setImageType(imageType)}
            setImages={(data) => setImages(prevState => ({ ...prevState, faceAvatar: data }))}
          />
          <ImageWrapper
            transactionCode={transactionCode}
            filetype={pageEnabled ? EFileTypes.faceImage: ''}
            path={location.pathname}
            setImageType={(imageType) => setImageType(imageType)}
            setImages={(data) => setImages(prevState => ({ ...prevState, faceImage: data }))}
          />
        </div>
        {!!imageType && <Modal
          show={!!imageType}
          onHide={() => setImageType('')}
        >
          <div className='face-image-wrapper-container'>
            <ImageWrapper
              transactionCode={transactionCode}
              image={imageType === EFileTypes.faceImage ? images.faceImage : images.faceAvatar}
              enableZoom={true}
            />
          </div>
        </Modal>}
        <div className="review-section">
          <p className="face-verification-status">
            {t('identity.fv.result.title')}
            <span className={result}>{pageEnabled && t(statusMessages[result])}</span>
          </p>
          <div className="line" />
          <div className="review">
            <span className='review-text'>{t('identity.fv.guideline.heading')}</span>
            {
              acceptances.map((item, index) => (
                <div className='checkbox-section' key={index}>
                  <input
                    type="checkbox"
                    checked={item.flag}
                    disabled={!pageEnabled}
                    onChange={(event) => {
                      if (!event.target.checked &&
                        !hasActiveAcceptanceCheck(acceptanceChecks,
                          [
                            EAcceptanceCheckNames.faceVerificationPhysicalSigns,
                            EAcceptanceCheckNames.faceVerificationSamePerson
                          ],
                          item.name)
                      ) {
                        dispatch(batchActions([
                          reviewResultActions.setFaceVerificationReview({
                            result: '',
                          }),
                          reviewResultActions.setRejectionReasons({
                            ...rejectionReasons,
                            faceVerification: [],
                          }),
                        ]));
                      }
                      dispatch(reviewResultActions.setAcceptanceChecks({
                        ...acceptanceChecks,
                        [item.name]: event.target.checked
                      }))
                    }}
                  />
                  <p className='review-description'>
                    {t(`identity.fv.guideline.message${index + 1}`)}
                    <ToolTip
                      placement='top'
                      tooltipValue={
                        <>
                          <span>{t(item.translationKeys.heading)}</span>
                          <ul>
                            {
                              item.translationKeys.listItems.map((key: string, index: number) => (
                                <li key={index}>{t(key)}</li>
                              ))
                            }
                          </ul>
                        </>
                      }
                    >
                      <Info />
                    </ToolTip>
                  </p>
                </div>
              ))
            }
          </div>
          <div className="line" />
          <div className="review-buttons">
            <h2 className="description">{t('review-footer.fv.question.title')}</h2>
            <div className='answer-section'>
              <Form.Check
                inline
                label={t('review-footer.answer-yes.title')}
                value={EAnswers.yes}
                name='identity.fv.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                disabled={!acceptances.some(item => item.flag)}
                checked={identityReview.faceVerificationReview.result === EAnswers.yes}
              />
              <Form.Check
                inline
                label={t('review-footer.answer-no.title')}
                value={EAnswers.no}
                name='identity.fv.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                disabled={!acceptances.some(item => item.flag)}
                checked={identityReview.faceVerificationReview.result === EAnswers.no}
              />
            </div>
            <div className='select-reasons'>
              <MultipleSelect
                disabled={!(acceptances.some(item => item.flag) &&
                  identityReview.faceVerificationReview.result === EAnswers.no)}
                {...rejectionReasonsProps}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaceVerification;

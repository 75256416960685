
import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Pencil } from 'images/pencil.svg';
import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Next } from 'images/next.svg';
import { formatDate } from 'util/common';
import { KEY_CODE } from 'constants/Static';
import { EFields } from 'enums';

const DatePickerComponent = (props: any) => {
  const {
    field,
    fieldValue,
    validated,
    setFieldValue,
    refDlContainer,
    disabled,
    autoFocus = true,
    onFocus,
    onBlur,
    preventOpenOnFocus = false,
    className,
  } = props;
  const { t } = useTranslation();
  const [year, setYear] = useState(fieldValue && moment(fieldValue, 'YYYY-MM-DD', true).isValid()
      && new Date(fieldValue)? new Date(fieldValue).getFullYear() : new Date().getFullYear());
  const [month, setMonth] = useState(fieldValue && moment(fieldValue, 'YYYY-MM-DD', true).isValid()
      && new Date(fieldValue)? new Date(fieldValue).getMonth() : new Date().getMonth() );
  const elementRef = useRef<HTMLButtonElement | null>(null);
  const [hideOnScroll, setHideOnScroll] = useState(false);

  const convertTimeZone = (date: Date) => {
    const utcDate = date.getTime() + date.getTimezoneOffset() * 60000;
    return new Date(utcDate);
  };

  const fieldData = fieldValue
    && moment(fieldValue, 'YYYY-MM-DD', true).isValid() && new Date(fieldValue) ?
    convertTimeZone(new Date(fieldValue)) : null;

  useEffect(() => {
    setMonth(fieldValue && moment(fieldValue, 'YYYY-MM-DD', true).isValid()
      && new Date(fieldValue) ? new Date(fieldValue).getMonth() : new Date().getMonth());
    setYear(fieldValue && moment(fieldValue, 'YYYY-MM-DD', true).isValid()
      && new Date(fieldValue)? new Date(fieldValue).getFullYear() : new Date().getFullYear());
  }, [fieldValue]);

  useEffect(() => {
    if (refDlContainer) {
      refDlContainer.current?.addEventListener('scroll', () => {
        setHideOnScroll(true);
      }, { passive: true });
      return () => refDlContainer.current?.removeEventListener('scroll', () => {
        setHideOnScroll(false);
      }, { passive: true });
    }
  }, []);

  useEffect(() => {
    const focusedElementsId = [
      EFields.dateOfBirth,
      EFields.expirationDate,
      EFields.dateOfIssue,
      EFields.dateOfEntry,
    ];
    if (focusedElementsId.includes(field) && elementRef !== null && elementRef.current !== null) {
      elementRef.current.focus();
    }
  });

  const renderResult = (selectedDate: Date | null, customInput: React.JSX.Element) => (
    <DatePicker
      preventOpenOnFocus={preventOpenOnFocus}
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={autoFocus}
      dateFormat="dd-MM-yyyy"
      renderCustomHeader={
        ({
          changeYear,
          decreaseMonth,
          increaseMonth,
        }) => renderCustomCalendarHeader({ changeYear, decreaseMonth, increaseMonth })
      }
      disabled={disabled}
      selected={selectedDate}
      onChange={
        (date: any) => {
          setMonth(date ? date.getMonth() : null);
          setYear(date ? date.getFullYear() : null);
          setFieldValue(formatDate(date, 'YYYY-MM-DD'));
        }
      }
      customInput={customInput}
      placeholderText="__-__-____"
      onCalendarOpen={() => { setHideOnScroll(false); }}
      popperPlacement="top"
      popperModifiers={{
        offset: {
          enabled: true,
          offset: '0px, 0px'
        },
        preventOverflow: {
          enabled: true,
          escapeWithReference: false,
          boundariesElement: 'viewport',
        },
      }}
      closeOnScroll={() => hideOnScroll}
      className={className}
    />
  );

  const months = [
    t('calendar-months.jan'),
    t('calendar-months.feb'),
    t('calendar-months.march'),
    t('calendar-months.apr'),
    t('calendar-months.may'),
    t('calendar-months.june'),
    t('calendar-months.july'),
    t('calendar-months.aug'),
    t('calendar-months.sep'),
    t('calendar-months.oct'),
    t('calendar-months.nov'),
    t('calendar-months.dec'),
  ];

  const onDecreaseYear = (changeYear: Function) => {
    setYear((currentYear) => {
      if (currentYear - 1 < 1900) {
        changeYear(1900);
        return 1900;
      }
      if (currentYear - 1 > 2100) {
        changeYear(2100);
        return 2100;
      }
      changeYear(currentYear - 1);
      return currentYear - 1;
    });
  };

  const onIncreaseYear = (changeYear: Function) => {
    setYear((currentYear) => {
      if (currentYear + 1 < 1900) {
        changeYear(1900);
        return 1900;
      }
      if (currentYear + 1 > 2100) {
        changeYear(2100);
        return 2100;
      }
      changeYear(currentYear + 1);
      return currentYear + 1;
    });
  };

  const onYearInputChange = (ev: any, changeYear: Function) => {
    const changedYear = Number(ev.target.value);
    setYear(changedYear);
    if (changedYear < 1900) return;
    if (changedYear > 9999 && changedYear < 21000) {
      setYear(Math.floor(changedYear/10));
      changeYear(Math.floor(changedYear/10));
      return Math.floor(changedYear/10);
    } else if ((changedYear > 2099 && changedYear < 10000) || changedYear >= 21000) {
      setYear(2100);
      changeYear(2100);
      return 2100;
    }
    changeYear(changedYear);
  };

  const onDecreaseMonth = (decreaseMonth: Function, changeYear: Function) => {
    setMonth((currentMonth) => {
      decreaseMonth();
      if (currentMonth === 0) {
        changeYear(year - 1);
        setYear(year - 1);
        return currentMonth + 11;
      }
      return currentMonth - 1;
    });
  };

  const onIncreaseMonth = (increaseMonth: Function, changeYear: Function) => {
    setMonth((currentMonth) => {
      increaseMonth();
      if (currentMonth === 11) {
        changeYear(year + 1);
        setYear(year + 1);
        return currentMonth - 11;
      }
      return currentMonth + 1;
    });
  };

  const renderCustomCalendarHeader = ({ changeYear, decreaseMonth, increaseMonth }: any) => (
    <div className="custom-calendar-container">
      <div className="custom-calendar-element">
        <Back
          onClick={() => onDecreaseYear(changeYear)}
        />
        <div className="custom-calendar-year">
          <input
            value={!year ? +(fieldValue.split('-')[0]) : year}
            type="number"
            onChange={(ev) => onYearInputChange(ev, changeYear)}
          />
          <Pencil />
        </div>
        <Next
          onClick={() => onIncreaseYear(changeYear)}
        />
      </div>

      <div className="custom-calendar-element">
        <Back
          onClick={() => onDecreaseMonth(decreaseMonth, changeYear)}
        />
        <span>
          {' '}
          {!months ? months[+(fieldValue.split('-')[1]) - 1] : months[month]}
          {' '}
        </span>
        <Next
          onClick={() => { onIncreaseMonth(increaseMonth, changeYear); }}
        />
      </div>
    </div>
  );

  const keyPress: Function = (e: KeyboardEvent) => {
    if (fieldValue && e.keyCode === KEY_CODE.ENTER) {
      e.preventDefault();
    }
  };

  const borderStyle = validated ? 'default-border' : 'error-border';

  const maskedInput = (
    <MaskedInput
      type="text"
      mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      onKeyUp={(e) => { keyPress(e); }}
      className={`date-picker-item ${borderStyle}`}
    />
  );

  return (
    <div className="date-picker-main">
      { renderResult(fieldData, maskedInput) }
    </div>
  );
};

export default DatePickerComponent;

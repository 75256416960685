import { ECountry, EDocumentType, EFields, EImageSide, ERoutePaths } from 'enums';
import { IGenericProps } from 'types';

export const PERMIT = 'permit';
export const SOMETHING = 'something';
export const UNKNOWN = 'Unknown';
export const PASS = 'pass';
export const LOCAL_STORAGE_LNG = 'i18nextLng';
export const DEFAULT_EXPIRE_VALUE = '**********';

export const OTHER_IMAGE_SIDES = [
  EImageSide.other,
  EImageSide.signature,
];

export const BUTTON_TYPE = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  OPTION_BUTTON: 'optionButton',
  RADIO_BUTTON: 'radioButton',
  SELECT_OPTION: 'selectOption',
};

export const BUTTON_STYLE = {
  OUTLINE_PRIMARY: 'outline-primary',
  PRIMARY: 'primary',
  OUTLINE_SECONDARY: 'outline-secondary',
  SECONDARY: 'secondary',
  BIG: 'big',
  OUTLINE_BIG: 'big-outline',
  INLINE_BIG: 'big-inline',
  CIRCLE_BUTTON: 'circle-button',
  CIRCLE_GROUP_BUTTONS: 'circle-group-buttons',
  CIRCLE_BUTTON_START: 'circle-group-buttons-start',
  CIRCLE_BUTTON_END: 'circle-group-buttons-end',
};

export const KEY_CODE = {
  TAB: 9,
  ENTER: 13,
  SPACE: 32,
};

export const SELF_DECLARATION_EXTRACTED_DIFFERENCE: IGenericProps = {
  dateOfBirth: 'birthdate',
  documentCountry: 'countryCode',
}

export const MRZ_INFO_MAPPING: { [files in EFields]?: string } = {
  lastName: 'primaryIdentifier',
  firstName: 'secondaryIdentifier',
  expirationDate: 'dateOfExpiry',
  documentCountry: 'issuingState',
}

export const DRIVING_FIELDS = ['value', 'issuingDate', 'restrictions', 'expiryDate'];

export const DEFAULT_DRIVING_FIELDS = {
  value: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  issuingDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: false,
  },
  expiryDate: {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
  restrictions:  {
    extractedValue: '',
    reviewedValue: '',
    optional: true,
  },
}

export const DUID_LABEL_MAPPING: IGenericProps = {
  'che-driving_licence-2003': '2003',
  'che-driving_licence-2023': '2023',
}

export const COMPARE_LIST = [
  EFields.lastName,
  EFields.firstName,
  EFields.documentNumber,
];

export const LOADING_DURATION = 10 * 1000; // 10 seconds

export const SUPPORTED_EXTRA_DETAILS = [
  `${EDocumentType.plasticPermit}-${ECountry.che}`,
  `${EDocumentType.paperPermit}-${ECountry.che}`,
  `${EDocumentType.id}-${ECountry.can}`,
  `${EDocumentType.id}-${ECountry.usa}`,
  `${EDocumentType.drivingLicence}-${ECountry.can}`,
  `${EDocumentType.drivingLicence}-${ECountry.usa}`,
  `${EDocumentType.healthCard}-${ECountry.can}`,
];

export const DEEP_LINK_ALLOWED_URLS = [
  ERoutePaths.root,
  ERoutePaths.signIn,
  ERoutePaths.logOut,
  ERoutePaths.dashboard,
];

export const DEFAULT_DELAY = 300;
import React from 'react';
import { AxiosResponse } from 'axios';
import Pagination from 'react-bootstrap/Pagination';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IRootState } from 'store/types';
import { ExportFileAPI } from 'helper/api/route';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as DownloadButtonIcon } from 'images/download.svg';
import { downloadFile } from 'util/common';
import { TGetTransactionOptions } from 'types';
import { getSelectedDataset } from 'helper';

interface IPaginationProps {
  getTransactions: (options: TGetTransactionOptions) => void;
}

const TablePagination = (props: IPaginationProps) => {
  const { getTransactions } = props;
  const { t } = useTranslation();
  const state = useSelector((rootState: IRootState) => rootState);
  const dashboard = state?.common?.dashboard;
  const { page = 1, size = 0, totalItems = 0 } = dashboard || {};
  const totalPages = Math.ceil(totalItems / size) || 1;
  const datasetId = getSelectedDataset(state.agent?.datasets)?.id;

  const exportCSV = () => {
    ExportFileAPI(String(datasetId)).then((response: AxiosResponse) => {
      downloadFile(response.data);
    });
  };

  const startIndex = (page - 1) * size + 1;
  const endIndex = page !== totalPages ? page * size : totalItems;

  return (
    <>
      {totalItems ? (
        <div className='transactions-table-pagination'>
          {!dashboard?.active ? <div className="export-button-container">
            <SingleButton
              onClick={exportCSV}
              textButton={'dashboard.export.btn'}
              sizeButton={BUTTON_STYLE.BIG}
              radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              variant={BUTTON_STYLE.OUTLINE_PRIMARY}
              LeftIcon={DownloadButtonIcon}
            />
          </div> : null}
          <Pagination>
            <Pagination.Item disabled className="displayText">
              {`${t('dashboard.paginator.label1')}
              ${startIndex}-${endIndex} ${t('dashboard.paginator.label2')} ${totalItems}`}
            </Pagination.Item>
            <div className="btnCollection">
              <Pagination.Prev
                className={`bttArrow ${startIndex > 1 ? 'active' : ''}`}
                onClick={() => getTransactions({ specificPage: page - 1 })}
                disabled={startIndex === 1}
              />
              <Pagination.Next
                className={`bttArrow ${endIndex !== totalItems ? 'active' : ''}`}
                onClick={() => getTransactions({ specificPage: page + 1 })}
                disabled={endIndex === totalItems}
              />
            </div>
          </Pagination>
        </div>)
        : null}
    </>
  );
};

export default TablePagination;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import langs from 'lang/languages';
import { getUrlParams } from 'util/common';
import ach from 'lang/ach.lang.json';
import { getStorageData } from 'util/storageHelper';

const initI18n = (language = '') => {
  const langsConfig = JSON.parse(getStorageData('appConfig') || '{}').lang;
  const DETECTION_OPTIONS = {
    order: ['navigator'],
  };

  const isTranslated: boolean = getUrlParams().translate === 'true';
  const ACH = 'ach';
  let defaultLang = 'en';
  let config: any;

  if (process.env.REACT_APP_ENV === 'CROWDIN' && isTranslated) {
    config = {
      fallbackLng: ACH,
      resources: {
        ach: {
          translation: ach,
        },
      },
      supportedLngs: [ACH],
      keySeparator: false,
    };
  } else {
    const getLanguage = () => {
      if (window.localStorage.i18nextLng === ACH) return defaultLang;
      if (language) return language;
      return window.localStorage.i18nextLng || defaultLang;
    };

    if (langsConfig) {
      defaultLang = langsConfig.default;
    }
    config = {
      detection: DETECTION_OPTIONS,
      resources: langs,
      fallbackLng: defaultLang,
      lng: getLanguage(),
      supportedLngs: langsConfig.langs,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    };
  }

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(config);
};

export const t = i18n.t.bind(i18n);
export default initI18n;

import produce from 'immer';

import { setStorageDataItemByKey, setStorageDataItem } from 'util/storageHelper';
import {
  ICommon,
  TCommonActions,
  CommonActions,
} from 'store/features/common/types';
import getInitialState from 'store/features/common/initialState';

export const commonReducer = (state: ICommon = getInitialState(), action: TCommonActions) => {
  const nextState = produce(state, (draftState: ICommon) => {
    switch (action.type) {
    case CommonActions.setDashboardData:
      draftState.dashboard = action.value;
      break;
    case CommonActions.setLoading:
      draftState.loading = action.value;
      break;
    case CommonActions.setDocRequiredFields:
      draftState.docRequiredFields = action.value;
      setStorageDataItemByKey('docRequiredFields', action.value);
      break;
    case CommonActions.setDefaultTransactionCode:
      draftState.defaultTransactionCode = action.value;
      setStorageDataItem('defaultTransactionCode', action.value);
      break;
    case CommonActions.setAppConfig:
      draftState.appConfig = action.value;
      setStorageDataItem('appConfig', action.value);
      break;
    }
    return draftState;
  });

  return nextState;
};

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ERoutePaths } from 'enums';
import { isAuthorized, logOut } from 'util/authService';
import { IRootState } from 'store/types';
import { GetAgentAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import * as agentActions from 'store/features/agentManagement/actions';
import * as commonActions from 'store/features/common/actions';

const ConditionalRedirect = () => {
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const isUserAuthorizedWithoutAgent = isAuthorized() && !state.agent;

  useEffect(() => {
    if (isUserAuthorizedWithoutAgent) {
      dispatch(commonActions.setLoading(true));
      GetAgentAPI().then((response) => {
        dispatch(agentActions.setAgent(response.data));
        dispatch(commonActions.setLoading(false));
      }).catch(() => {
        dispatch(commonActions.setLoading(false));
        logOut();
      });
    }
  }, [state.agent]);

  if (state.common?.loading || isUserAuthorizedWithoutAgent) {
    return <div className="loader-container"> <Loader /></div>;
  }

  if (!isAuthorized()) {
    return <Navigate to={ERoutePaths.signIn} />;
  }
  return <Navigate to={ERoutePaths.dashboard} />;
};

export default ConditionalRedirect;

import en from 'lang/en.lang.json';
import de from 'lang/de.lang.json';

const langs = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
};

export default langs;

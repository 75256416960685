import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

interface IModalProps {
  onHide: (value: boolean) => void;
  show: boolean;
  children?: React.ReactNode;
  heading?: string;
}

const CustomModal = (props: IModalProps) => {
  const { t } = useTranslation();
  const { show, children, onHide, heading } = props;
  return (
    <Modal
      show={show}
      onHide={() => onHide(false)}
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          {t(`${heading || ''}`)}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;

import React from 'react';
import { useTranslation } from 'react-i18next';

import ListFromResources from 'components/accordionMenu/ListFromResources';

const Knowledge = () => {
  const { t } = useTranslation();

  return (
    <ListFromResources resource={[
      [
        {
          text: t('review-header.help.message5'),
          type: 'link',
          // eslint-disable-next-line max-len
          url: 'https://support.pxl-vision.com/_hcms/mem/login?redirect_url=https%3A%2F%2Fsupport.pxl-vision.com%2Fknowledge%2Fmanual-review#manual-identity-review-training-guide'
        },
        ` ${t('review-header.help.message6')}`,
      ],
      [
        {
          text: t('review-header.help.message7'),
          type: 'link',
          url: 'https://support.pxl-vision.com/knowledge/manual-review#manual-identity-review-training-guide'
        },
        ` ${t('review-header.help.message8')}`,
      ],
      [
        {
          text: t('review-header.help.message9'),
          type: 'link',
          url: 'https://support.pxl-vision.com/knowledge/pxl-check-user-guide'
        },
        ` ${t('review-header.help.message10')}`,
      ],
    ]} />
  );
};

export default Knowledge;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as Back } from 'images/back.svg';
import { ReactComponent as Next } from 'images/next.svg';
import { getImageFromBase64 } from 'util/common';
import { getNotFoundImage } from 'helper';
import { IRootState } from 'store/types';
import { EDirection, EDocumentType } from 'enums';
import { BUTTON_STYLE, BUTTON_TYPE, LOADING_DURATION } from 'constants/Static';
import ButtonTypes from 'components/buttonTypes/ButtonTypes';
import { IAnswerOption, ISpecimen } from 'types';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import Loader from 'components/loader/Loader';
import { SendEmailAPI } from 'helper/api/route';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';

const SLIDER_SIZE = 4;
const ANSWERS_KEYS = {
  SELECT: 'select',
  NOT_FOUND: 'not-found',
};

interface ISpecimenProps {
  firstImage: string;
  secondImage: string;
  onHide: (value: boolean) => void;
  specimens: ISpecimen[];
}

const Specimen = (props: ISpecimenProps) => {
  const { firstImage, secondImage, onHide, specimens } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { manualCheckResult, transactionCode } = state.reviewResult;
  const { identityReview } = manualCheckResult;
  const { documentValidationReview } = identityReview;
  const [{ documentType, documentCountry }] = documentValidationReview;
  const [specimenData, setSpecimenData] = useState({
    frontSpecimenLoaded: false,
    backSpecimenLoaded: false,
    frontUnavailable: false,
    backUnavailable: false,
  });
  const page = {
    answers: [
      {
        text: 'specimen.btn.not-found',
        buttonType: BUTTON_TYPE.OPTION_BUTTON,
        key: ANSWERS_KEYS.NOT_FOUND,
      },
      {
        text: 'specimen.btn.select',
        buttonType: BUTTON_TYPE.OPTION_BUTTON,
        key: ANSWERS_KEYS.SELECT,
      },
    ],
    answerComponent: Selection,
  };

  const currentIndex = Math.max(
    0,
    specimens.findIndex((item: any) => item.duid === documentValidationReview[0].duid)
  );

  const [sliderTab, setSliderTab] = useState(
    Math.floor(currentIndex / SLIDER_SIZE) + 1
  );

  useEffect(() => {
    setSliderTab(Math.floor(currentIndex / SLIDER_SIZE) + 1);
    dispatch(reviewResultActions.setDocumentValidation({
      duid: specimens[currentIndex].duid,
    }));
  }, [currentIndex]);

  const { back = '', front = '', duid = '' } = specimens[currentIndex] || {};

  useEffect(() => {
    setSpecimenData({
      frontSpecimenLoaded: false,
      backSpecimenLoaded: false,
      frontUnavailable: false,
      backUnavailable: false,
    });
    const frontSpecimenImage = new Image();
    const backSpecimenImage = new Image();
    frontSpecimenImage.src = front;
    backSpecimenImage.src = back;
    frontSpecimenImage.onload = () => {
      setSpecimenData((prevState) => ({
        ...prevState,
        frontSpecimenLoaded: true,
      }));
    };
    frontSpecimenImage.onerror = () => {
      setSpecimenData((prevState) => ({
        ...prevState,
        frontUnavailable: true,
      }));
    };
    backSpecimenImage.onload = () => {
      setSpecimenData((prevState) => ({
        ...prevState,
        backSpecimenLoaded: true,
      }));
    };
    backSpecimenImage.onerror = () => {
      setSpecimenData((prevState) => ({ ...prevState, backUnavailable: true }));
    };
    const interval = setInterval(() => {
      if (!specimenData.frontSpecimenLoaded) {
        setSpecimenData((prevState) => ({
          ...prevState,
          frontSpecimenLoaded: true,
        }));
      }
      if (!specimenData.backSpecimenLoaded) {
        setSpecimenData((prevState) => ({
          ...prevState,
          backSpecimenLoaded: true,
        }));
      }
    }, LOADING_DURATION);
    return () => clearTimeout(interval);
  }, [duid]);

  const changeSpecimen = (direction: EDirection) => {
    const index =
      currentIndex + direction < 0
        ? specimens.length - 1
        : (currentIndex + direction) % specimens.length;
    dispatch(reviewResultActions.setDocumentValidation({
      duid: specimens[index].duid,
    }));
  };

  const handleSpecimenSelection = (answer: IAnswerOption) => {
    const selectedItem = specimens.find(
      (item: ISpecimen) => item.duid === answer.key
    );
    if (selectedItem) {
      dispatch(reviewResultActions.setDocumentValidation({
        duid: selectedItem.duid,
      }));
    }
  };

  const specimenAnswers = specimens.map((item: ISpecimen, index: number) => ({
    text: item.duid?.split('-')[2] || '',
    key: item?.duid || '',
    buttonType:
      specimens.length === 1
        ? BUTTON_TYPE.OPTION_BUTTON
        : BUTTON_TYPE.RADIO_BUTTON,
    radius:
      specimens.length === 1
        ? BUTTON_STYLE.CIRCLE_BUTTON
        : index === 0
          ? BUTTON_STYLE.CIRCLE_BUTTON_START
          : index === specimens.length - 1
            ? BUTTON_STYLE.CIRCLE_BUTTON_END
            : BUTTON_STYLE.CIRCLE_BUTTON,
  }));
  const tabSize = Math.ceil(specimenAnswers.length / SLIDER_SIZE);
  const startIndex =
    sliderTab === tabSize
      ? specimenAnswers.length - SLIDER_SIZE
      : (sliderTab - 1) * SLIDER_SIZE;

  const filteredAnswers =
    specimenAnswers.length <= SLIDER_SIZE
      ? specimenAnswers
      : specimenAnswers.splice(startIndex, SLIDER_SIZE);

  const selectionAnswers = specimens.length
    ? page?.answers
    : page?.answers.filter(
      (answer: IAnswerOption) => answer.key === ANSWERS_KEYS.NOT_FOUND
    ) || [];

  const handleClick = (answer: IAnswerOption) => {
    if (answer.key === ANSWERS_KEYS.NOT_FOUND) {
      dispatch(reviewResultActions.setDocumentValidation({
        specimenNotFound: true,
      }));
      const docCountry = Countries.find((option) => option.value === documentCountry);
      const docType = DocumentTypes.find((option) => option.value === documentType);
      if (process.env.REACT_APP_ON_PREM !== 'true') {
        SendEmailAPI({
          country: t(docCountry?.label || ''),
          docType: t(docType?.label || ''),
          trCode: transactionCode,
          agentEmail: state.agent?.info.email,
        });
      }
    }
    if (answer.key === ANSWERS_KEYS.SELECT) {
      dispatch(reviewResultActions.setDocumentValidation({
        specimenNotFound: false,
      }));
    }
    onHide(true);
  };
  return (
    <div className='template-container'>
      <div className='document-template-image-container'>
        <div className='image-container-doc'>
          <div className='images-container'>
            {
              <div className='image-item'>
                {secondImage ? (
                  <img
                    className={'image-style'}
                    alt='back document'
                    src={getImageFromBase64(secondImage)}
                  />
                ) : (
                  <p className='no-image'>
                    {documentType === EDocumentType.paperPermit
                      ? t('specimen.doc.photoNotFound')
                      : t('specimen.doc.mrzNotFound')}
                  </p>
                )}
              </div>
            }
            {
              <div className='image-item'>
                {firstImage ? (
                  <img
                    className={'image-style'}
                    alt='front document'
                    src={getImageFromBase64(firstImage)}
                  />
                ) : (
                  <p className='no-image'>
                    {documentType === EDocumentType.paperPermit
                      ? t('specimen.doc.dataNotFound')
                      : t('specimen.doc.vizNotFound')}
                  </p>
                )}
              </div>
            }
          </div>
        </div>
        <div className='label-container'>
          {t('specimen.doc.sub-heading')}
        </div>
      </div>
      <div className='document-template-image-container'>
        {!specimens.length ? (
          <span className='template-not-available'>
            {t('specimen.doc.noTemplate')}
          </span>
        ) : (
          <>
            <div className='image-container-doc'>
              {specimens.length > 1 && (
                <div
                  className='left-arrow arrow-container'
                  role='button'
                  onClick={() => changeSpecimen(EDirection.left)}
                  tabIndex={0}
                >
                  <Back />
                </div>
              )}
              <div className='images-container'>
                {
                  <div className='image-item'>
                    {!specimenData.backSpecimenLoaded && (
                      <div className='loader-container'>
                        {' '}
                        <Loader />
                      </div>
                    )}
                    <img
                      className={'image-style'}
                      alt='back'
                      src={
                        specimenData.backUnavailable
                          ? getNotFoundImage(i18n.language)
                          : back
                      }
                      onLoad={() =>
                        setSpecimenData((prevState) => ({
                          ...prevState,
                          backSpecimenLoaded: true,
                        }))
                      }
                    />
                  </div>
                }
                {
                  <div className='image-item'>
                    {!specimenData.frontSpecimenLoaded && (
                      <div className='loader-container'>
                        {' '}
                        <Loader />
                      </div>
                    )}
                    <img
                      className={'image-style'}
                      alt='front'
                      src={
                        specimenData.frontUnavailable
                          ? getNotFoundImage(i18n.language)
                          : front
                      }
                      onLoad={() =>
                        setSpecimenData((prevState) => ({
                          ...prevState,
                          frontSpecimenLoaded: true,
                        }))
                      }
                    />
                  </div>
                }
              </div>
              {specimens.length > 1 && (
                <div
                  className='right-arrow arrow-container'
                  role='button'
                  onClick={() => changeSpecimen(EDirection.right)}
                  tabIndex={0}
                >
                  <Next />
                </div>
              )}
            </div>
            <div className='label-container'>{duid?.split('-')[2] || ''}</div>
            <div className='main-buttons'>
              {specimens.length > SLIDER_SIZE && (
                <div
                  className={`arrow-container ${
                    sliderTab === 1 ? 'disabled' : ''
                  }`}
                  role='button'
                  onClick={() => {
                    return (
                      sliderTab > 1 && setSliderTab(sliderTab + EDirection.left)
                    );
                  }}
                >
                  <Back />
                </div>
              )}
              {specimens.length && (
                <ButtonTypes
                  answers={filteredAnswers}
                  styled={BUTTON_STYLE.OUTLINE_SECONDARY}
                  activeIndexes={[
                    filteredAnswers.findIndex(
                      (item: IAnswerOption) => item.key === duid
                    ),
                  ]}
                  handleClick={handleSpecimenSelection}
                />
              )}
              {specimens.length > SLIDER_SIZE && (
                <div
                  className={`arrow-container ${
                    sliderTab === tabSize ? 'disabled' : ''
                  }`}
                  role='button'
                  onClick={() =>
                    sliderTab < tabSize &&
                    setSliderTab(sliderTab + EDirection.right)
                  }
                >
                  <Next />
                </div>
              )}
              <ButtonTypes
                answers={selectionAnswers}
                styled={BUTTON_STYLE.OUTLINE_SECONDARY}
                activeIndexes={[(selectionAnswers || [])?.length - 1]}
                handleClick={handleClick}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Specimen;

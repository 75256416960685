import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SignIn from 'pages/signIn/SignIn';
import ExternalSignIn from 'pages/signIn/ExternalSignIn';
import Dashboard from 'pages/dashboard/Dashboard';
import PublicRoute from 'routing/PublicRoute';
import PrivateRoute from 'routing/PrivateRoute';
import * as rootActions from 'store/actions';
import * as commonActions from 'store/features/common/actions';
import { IRootState } from 'store/types';
import LogOut from 'pages/logout/LogOut';
import { UnlockTransactionAPI } from 'helper/api/route';
import { ERoutePaths } from 'enums';
import ForgotPassword from 'pages/forgotPassword/ForgotPassword';
import NewPassword from 'pages/newPassword/NewPassword';
import ConditionalRedirect from 'routing/ConditionalRedirect';
import TABoarding from 'pages/taBoard/TABoarding';
import Review from 'pages/review/Review';
import { DEEP_LINK_ALLOWED_URLS } from 'constants/Static';

const Routing = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { transactionCode } = state.reviewResult;
  const appConfig = state.common?.appConfig;
  const location = useLocation();

  useEffect(() => {
    const tid = new URLSearchParams(location.search).get('tid');
    const lang = new URLSearchParams(location.search).get('lang');
    if (tid) {
      const deeppinkTransaction = DEEP_LINK_ALLOWED_URLS.some((url) => location.pathname.includes(url))
        ? tid : '';
      dispatch(commonActions.setDefaultTransactionCode(deeppinkTransaction));
      window.history.replaceState({}, '', location.pathname);
    }
    if (lang && appConfig?.lang?.langs.includes(lang)) {
      i18n.changeLanguage(lang);
    }
    if (transactionCode && location.pathname === ERoutePaths.dashboard) {
      dispatch(rootActions.resetSession());
      if (transactionCode) UnlockTransactionAPI(transactionCode);
    }
    window.addEventListener('popstate', () => window.location.replace(ERoutePaths.root), false);
    return () => window.removeEventListener('popstate', () => ({}));
  }, [location]);

  return (
    <Routes>
      <Route
        path={ERoutePaths.signIn}
        element={<PublicRoute component={!appConfig?.ssoConfig ? <SignIn /> : <ExternalSignIn />} />}
      />
      <Route
        path={ERoutePaths.forgottenPassword}
        element={<PublicRoute component={<ForgotPassword />}/>}
      />
      <Route
        path={ERoutePaths.logOut}
        element={<PublicRoute component={<LogOut />}/>}
      />
      <Route
        path={ERoutePaths.setPassword}
        element={<PublicRoute component={<NewPassword />}/>}
      />
      <Route
        path={ERoutePaths.dashboard}
        element={<PrivateRoute component={<Dashboard />}/>}
      />
      <Route
        path={'/ta-boarding/:transactionCode'}
        element={<PrivateRoute component={<TABoarding />}/>}
      />
      {/* Will be implemented in the future after adding the corresponding pages
      {[ERoutePaths.identityReview, ERoutePaths.addressReview, ERoutePaths.a4scanReview].map( */}
      {[ERoutePaths.identityReview].map(
        (pathname, index) => <Route
          key={index}
          path={`${pathname}/:transactionCode`}
          element={<PrivateRoute component={<Review />}/>}
        />
      )}
      <Route path="*" element={<ConditionalRedirect />} />
    </Routes>
  );
};

export default Routing;

import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';

import { ReactComponent as DownIcon } from 'images/arrowDown.svg';
import { ReactComponent as UpIcon } from 'images/arrowUp.svg';
import { useTranslation } from 'react-i18next';

const ContextAwareToggle =
  ({ children, icon, eventIcon, statusText, status, isOpensFields }: any) => {
    const { t } = useTranslation();

    return (
      <div className={'accordion-title'}>
        <div className="left-info">
          {eventIcon ? eventIcon :
            isOpensFields ? <UpIcon className="up-icon" /> : <DownIcon className="up-icon" />}
          <p className="accordion-title-info">{children}</p>
        </div>
        <div className={`status-title ${status}`}>
          <span className={`right-text ${statusText?.status}`}>
            {statusText &&
            Object.keys(statusText).length ?
              statusText.texts?.map((text: string) => {
                return t(text) + ' ';
              })
              : ''
            }
          </span>
          <span className="right-end-icon">{icon}</span>
        </div>
      </div>
    );
  };

const AccordionField = (props: any) => {
  const { title, body, icon,
    className, eventIcon, statusText,
    status, showStatus, eventKey,
    setStatusByEventKey } = props;
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(showStatus);
  }, [showStatus]);

  return (
    <div className={className || 'default-acordion-style'}>
      <Accordion key={eventKey}>
        <Card>
          <Card.Header
            onClick={() => {
              setIsOpen(!isOpen);
              setStatusByEventKey && setStatusByEventKey(eventKey);
            }}
            onKeyPress={() => { setIsOpen(!isOpen); }}
            tabIndex={eventKey}
            key={eventKey}
          >
            <ContextAwareToggle
              eventKey={eventKey}
              icon={icon}
              eventIcon={eventIcon}
              statusText={statusText}
              status={status}
              isOpensFields={isOpen}
            >
              {typeof (title) === 'object' &&
                Object.keys(title).length ?
                title.texts?.map((text: string) => {
                  return t(text) + ' ';
                })
                :
                t(title)
              }
            </ContextAwareToggle>
          </Card.Header>
          <Accordion.Collapse
            eventKey={eventKey}
            in={isOpen}
          >
            <Card.Body>{body}</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};

export default AccordionField;
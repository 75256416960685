import React from 'react';
import { default as ReactSelect } from 'react-select';
import { components } from 'react-select';

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          className="inputCheckbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultipleSelect = (props: any) => {
  const {
    handler,
    placeholder,
    options,
    value,
    disabled,
    isSearchable,
    classNamePrefix,
    className,
    onKeyDown,
    autoFocus,
    inputBorder,
    menuBorder,
    styledScrollBar,
  } = props;

  const defaultTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: 'none',
      primary50: '#e6e6e6',
      primary: '#ccc',
    },
  });

  const customStyles = {
    IndicatorsContainer: (base: any) => ({
      ...base,
      height: '100%',
      display: 'none',
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      pointerEvents: 'none',
      display: 'none',
      backgroundColor: 'unset',
    }),
    valueContainer: (base: any) => ({
      ...base,
      overflowY: 'scroll',
      height: '3rem',
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      '&:hover': {
        backgroundColor: '#E9425E',
        color: 'white',
        cursor: 'pointer',
      },
    }),
    option: (base: any, state: any) => ({
      ...base,
      '&:hover': {
        backgroundColor: state.isFocused ? '#e0e0e0' : 'none',
        cursor: 'pointer',
      },
    }),
    control: (base: any, state: any) => ({
      ...base,
      minHeight: '100%',
      borderRadius: state.selectProps.inputType === 'circle' ? '1rem' : '6px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0'
    }),
    menuList: (base: any, state: any) => ({
      ...base,
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0',
    }),
    container: (base: any, state: any) => ({
      ...base,
      height: '100%',
      borderRadius: state.selectProps.menuBorder === 'circle' ? '1rem' : '0',
    }),
    input: (base: any, state: any) => ({
      ...base,
      margin: 1,
      padding: 0
    }),
    item: (base: any, state: any) => ({
      ...base,
      height: state.selectProps.menuBorder === 'circle' ? '1rem' : '',
      minHeight: '100%',
    }),
    singleValue: (base: any) => ({
      ...base,
      maxWidth: '95%',
    }),
  };

  return <>
    <div className="multi-select-component">
      <ReactSelect
        menuPlacement={'top'}
        options={options}
        styles={customStyles}
        placeholder={placeholder}
        theme={(theme) => defaultTheme(theme)}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option }}
        onChange={(selectedOption: any) => handler(selectedOption)}
        allowSelectAll={true}
        value={value}
        menuPosition="fixed"
        isDisabled={disabled}
        isSearchable={isSearchable}
        classNamePrefix={classNamePrefix}
        onKeyDown={onKeyDown}
        autoFocus={autoFocus}
        className={`select-element_${className || 'valid'} ${styledScrollBar}`}
        menuBackgroundColor="white"
        inputType={inputBorder}
        menuBorder={menuBorder}
      />
    </div>
  </>
};

export default MultipleSelect;
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { IOptionType } from 'types';
import { EFields, EZones } from 'enums';
import { IRootState } from 'store/types';
import { fieldValueValidated } from 'helper/validation';
import { TFields, TFieldData } from 'store/features/reviewResult/types';
import ToolTip from 'components/toolTipField/ToolTipField';
import { isFieldCopyAllowed, areValuesEqual } from 'helper';
import { CompareFieldsAPI } from 'helper/api/route';
import useDebouncedValue from 'hooks/useDebouncedValue';
import { COMPARE_LIST, DEFAULT_DELAY } from 'constants/Static';

interface IInputProps {
  type: string;
  zones: EZones[];
  fieldKey: keyof TFields;
  fieldData?: TFieldData;
  setPersonalInfo: (newValues: TFieldData) => void;
  options?: Array<IOptionType>;
}

const FieldComparisonInput = (props: IInputProps) => {
  const { type, zones, fieldKey, fieldData, setPersonalInfo } = props;
  const { viz } = fieldData || {};
  const state = useSelector((state: IRootState) => state);
  const { reviewResult } = state;
  const { transactionCode } = reviewResult;
  const [mismatch, setMismatch] = useState(false);
  const { t } = useTranslation();

  const detectedFieldType = useMemo(() => zones.find((zone) => zone !== EZones.viz), [zones]) as keyof typeof EZones;

  const { extractedValue = '', editable = true, disabled = false, optional = false } = viz || {};
  let { value: vizValue = '' } = viz || {};
  if (!editable) {
    vizValue = t('identity.doc.data.not-applicable');
  }

  const {
    extractedValue: detectedValueIdentifier = '',
    editable: detectedFieldEditable = true,
    disabled: detectedFieldDisabled = false,
    optional: detectedFieldOptional = false,
  } = fieldData?.[detectedFieldType] || {};
  let { value: detectedValue = '' } = fieldData?.[detectedFieldType] || {};
  if (!detectedFieldEditable) {
    detectedValue = t('identity.doc.data.not-applicable');
  }

  const [vizCopied, setVizCopied] = useState(!areValuesEqual(extractedValue, vizValue));
  const [detectedCopied, setDetectedCopied] = useState(!areValuesEqual(detectedValueIdentifier, detectedValue));

  const allowToCopyVizValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones.viz,
      detectedValueIdentifier,
      optional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      disabled,
      detectedValue,
      vizValue
    );
  }, [
    detectedValue,
    detectedValueIdentifier,
    editable,
    detectedFieldEditable,
    disabled,
    optional,
    vizValue
  ]);

  const allowToCopyDetectedValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones[detectedFieldType],
      vizValue,
      detectedFieldOptional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      detectedFieldDisabled,
      vizValue,
      detectedValue
    );
  }, [
    detectedFieldType,
    detectedFieldEditable,
    detectedFieldOptional,
    editable,
    detectedFieldDisabled,
    detectedValue,
    vizValue
  ]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    zoneType: EZones,
  ) => {
    const targetValue = e.target.value;
    const valid = fieldValueValidated(
      EFields[fieldKey],
      zoneType,
      targetValue,
      fieldData?.[zoneType]?.optional || false
    );
    if (valid) {
      setPersonalInfo({
        [zoneType]: {
          ...fieldData?.[zoneType],
          value: targetValue,
        },
      });
      if (zoneType === EZones.viz) {
        setVizCopied(!areValuesEqual(extractedValue, targetValue));
      } else {
        setDetectedCopied(!areValuesEqual(detectedValueIdentifier, targetValue));
      }
    }
  };

  const debouncedVizValue = useDebouncedValue(vizValue, DEFAULT_DELAY);
  const debouncedDetectedFieldValue = useDebouncedValue(detectedValue, DEFAULT_DELAY);
  const prevVizValue = useRef(debouncedVizValue);
  const prevDetectedFieldValue = useRef(debouncedDetectedFieldValue);

  useEffect(() => {
    if (COMPARE_LIST.includes(fieldKey)) {
      CompareFieldsAPI(transactionCode, {
        [fieldKey]: {
          asciiString: debouncedDetectedFieldValue,
          unicodeString: debouncedVizValue,
        },
      }).then((compareResult) => {
        setMismatch(!compareResult.data[fieldKey].equals);
      }).catch(() => {
        if (areValuesEqual(debouncedVizValue, debouncedDetectedFieldValue)) {
          setMismatch(false);
          return;
        }
      });
    } else {
      setMismatch(!areValuesEqual(detectedValue, vizValue));
    }
    prevVizValue.current = debouncedVizValue;
    prevDetectedFieldValue.current = debouncedDetectedFieldValue;
  }, [debouncedVizValue, debouncedDetectedFieldValue, detectedValue, vizValue]);

  const copyValue = (fromVizToDetected: boolean) => {
    const sourceValue = fromVizToDetected ? vizValue : detectedValue;
    const targetZone = fromVizToDetected ? detectedFieldType : EZones.viz;
    const valid = fieldValueValidated(
      EFields[fieldKey],
      EZones[targetZone],
      sourceValue,
      fromVizToDetected ? detectedFieldOptional : optional
    );
    if (valid) {
      setPersonalInfo({
        [targetZone]: {
          ...fieldData?.[targetZone],
          value: sourceValue,
        },
      });
      if (fromVizToDetected) {
        setDetectedCopied(true);
      } else {
        setVizCopied(true);
      }
    }
  };

  return (
    <div className='custom-comparison-input-container'>
      <ToolTip placement='top' tooltipValue={t(`identity.doc.data.${fieldKey}`)} >
        <span className='custom-input-field-key'>{t(`identity.doc.data.${fieldKey}`)}: </span>
      </ToolTip>
      {zones.length === 1 ? (
        <div className='custom-input-container'>
          <input
            type={type}
            value={vizValue}
            readOnly={!viz?.editable}
            disabled={viz?.disabled}
            className={`custom-input
              ${(!areValuesEqual(extractedValue, vizValue)) ? 'copied' : ''}`
            }
            onChange={(e) => handleInputChange(e, EZones.viz)}
          />
        </div>
      ) : (
        <>
          <div className='custom-input-container'>
            <input
              type={type}
              value={vizValue}
              readOnly={!viz?.editable}
              disabled={viz?.disabled}
              className={`custom-input
                ${(detectedFieldEditable && mismatch) ? 'mismatch' : ''}
                ${(detectedFieldEditable && vizCopied) ? 'copied' : ''}
                ${!viz?.editable ? 'not-editable' : ''}`}
              onChange={(e) => handleInputChange(e, EZones.viz)}
            />
            <button
              onClick={() => copyValue(false)}
              className={allowToCopyVizValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyVizValue}
            >
              &#x276E;
            </button>
          </div>
          <div className='custom-input-container'>
            <button
              onClick={() => copyValue(true)}
              className={allowToCopyDetectedValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyDetectedValue}
            >
              &#x276F;
            </button>
            <input
              type={type}
              value={detectedValue}
              readOnly={!detectedFieldEditable}
              disabled={detectedFieldDisabled}
              className={`custom-input
                ${(viz?.editable && mismatch) ? 'mismatch' : ''}
                ${(viz?.editable && detectedCopied) ? 'copied' : ''}
                ${!detectedFieldEditable ? 'not-editable' : ''}`}
              onChange={(e) => handleInputChange(e, EZones[detectedFieldType])}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FieldComparisonInput;

import React from 'react';

import DocumentValidation from 'pages/identityReview/documentValidation/DocumentValidation';
import Liveness from 'pages/identityReview/liveness/Liveness';
import FaceVerification from 'pages/identityReview/faceVerification/FaceVerification';

const IdentityReview = () => {
  return (<>
    <div className='identity-review-content'>
      <div className='document-validation-content'>
        <DocumentValidation />
      </div>
      <div className='lfv-content'>
        <div className='liveness-content'>
          <Liveness />
        </div>
        <div className='face-verification-content'>
          <FaceVerification />
        </div>
      </div>
    </div>
  </>);
};

export default IdentityReview;

import { useSelector } from 'react-redux';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'images/pxlCheckLogo.svg';
import { ReactComponent as PersonIcon } from 'images/personIcon.svg';
import useOutsideClick from 'hooks/useOutsideClick';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import { logOut } from 'util/authService';
import { IRootState } from 'store/types';

const TopHeader = () => {
  const { t } = useTranslation();
  const [isMenuOpened, toggleMainMenu] = useState(false);
  const userRef = useRef(null);
  useOutsideClick(userRef, toggleMainMenu);
  const state = useSelector((rootState: IRootState) => rootState);
  const appConfig = state.common?.appConfig;

  return (
    <div className="top-header-container">
      <div>
        <div className='logo'>
          <Logo />
          <p>{ 'v' + appConfig?.version }</p>
        </div>
      </div>
      <div className="user-logo-container">
        <SwitchLanguage />
        <div className="person-icon-container" ref={userRef}>
          <PersonIcon
            tabIndex={0}
            onClick={(() => toggleMainMenu(!isMenuOpened))}
            onKeyPress={ () => toggleMainMenu(!isMenuOpened)}
          />
          {isMenuOpened && (
            <div className="menu-dropdown">
              <div className="menu-dropdown-item">
                <span
                  className="popup-content"
                  tabIndex={0}
                  onClick={() => logOut()}
                  onKeyPress={() => logOut()}
                >
                  {t('review-header.log-out')}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;

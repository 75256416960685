import React from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ISingleButtonProps } from 'types';

const SingleButton = (state: ISingleButtonProps) => {
  const { t } = useTranslation();
  const {
    textButton,
    onClick,
    variant,
    radiusButton,
    buttonData,
    isDisabled,
    isActive,
    sizeButton,
    buttonRef,
    LeftIcon,
    RigthIcon,
    className,
  } = state;
  return (
    <div className={`custom-single-buttons-container ${className}`}>
      <div
        className={
          `${variant} ${radiusButton} ${sizeButton} ${sizeButton ? '' : 'margin-buttons'}`
        }>
        <Button
          onClick={() => onClick(buttonData)}
          variant={variant}
          disabled={isDisabled}
          active={isActive}
          ref={buttonRef}
        >
          {LeftIcon && <LeftIcon />}
          { textButton ?
            t(textButton) :
            buttonData?.isTranslated ? buttonData?.text : t(buttonData?.text)}
          {RigthIcon && <RigthIcon />}
        </Button>
      </div>
    </div>
  );
};

export default SingleButton;
const ImageQualityRejectionReasons = [
  {
    'label': 'review-footer.doc.rejection-quality-reason-1.title',
    'value': 'Reflection'
  },
  {
    'label': 'review-footer.doc.rejection-quality-reason-2.title',
    'value': 'Blurriness'
  }
];

const DocumentRejectionReasons = [
  {
    'label': 'review-footer.doc.rejection-document-reason-1.title',
    'value': 'Not_Allowed'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-2.title',
    'value': 'Page_Mismatch'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-3.title',
    'value': 'Unacceptable_Document'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-4.title',
    'value': 'Expired_Or_Invalidated'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-5.title',
    'value': 'Missing_Signature'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-6.title',
    'value': 'Data_Mismatch'
  },
  {
    'label': 'review-footer.doc.rejection-document-reason-7.title',
    'value': 'Manipulated'
  }
];

const RecordRejectionReasons = [
  {
    'label': 'review-footer.doc.rejection-recording-reason-1.title',
    'value': 'Insufficient_Video_Quality'
  },
  {
    'label': 'review-footer.doc.rejection-recording-reason-2.title',
    'value': 'No_Acceptable_Document_In_The_Video'
  },
  {
    'label': 'review-footer.doc.rejection-recording-reason-3.title',
    'value': 'Some_security_Features_Do_Not_Look_Real'
  },
  {
    'label': 'review-footer.doc.rejection-recording-reason-4.title',
    'value': 'Insufficient_Security_Features'
  },
  {
    'label': 'review-footer.doc.rejection-recording-reason-5.title',
    'value': 'Unacceptable_Video'
  },
  {
    'label': 'review-footer.doc.rejection-recording-reason-6.title',
    'value': 'Cannot_Play_Video'
  }
];

const FaceVerificationRejectionReasons = [
  {
    'label': 'review-footer.fv.rejection-reason-1.title',
    'value': 'Different_Persons'
  },
  {
    'label': 'review-footer.fv.rejection-reason-2.title',
    'value': 'Fake_Photo'
  },
  {
    'label': 'review-footer.fv.rejection-reason-3.title',
    'value': 'Missing_Face'
  }
];

const LivenessRejectionReasons = [
  {
    'label': 'review-footer.ld.rejection-reason-1.title',
    'value': 'Covered_Face'
  },
  {
    'label': 'review-footer.ld.rejection-reason-2.title',
    'value': 'Masked_Face'
  },
  {
    'label': 'review-footer.ld.rejection-reason-3.title',
    'value': 'Insufficient_Video_Quality'
  },
  {
    'label': 'review-footer.ld.rejection-reason-4.title',
    'value': 'Not_A_Live_Person'
  },
  {
    'label': 'review-footer.ld.rejection-reason-5.title',
    'value': 'Manipulated'
  },
  {
    'label': 'review-footer.ld.rejection-reason-6.title',
    'value': 'Cannot_Play_Video'
  }
]

export {
  RecordRejectionReasons,
  DocumentRejectionReasons,
  LivenessRejectionReasons,
  ImageQualityRejectionReasons,
  FaceVerificationRejectionReasons,
};

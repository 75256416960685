export const setStorageDataItemByKey = (key: string, value: any): void => {
  const data = sessionStorage.getItem('state');
  const storageData = (data && JSON.parse(data)) || {};
  storageData[key] = value;
  sessionStorage.setItem('state', JSON.stringify(storageData));
};

export const setStorageDataItem = (key: string, value: any): void => {
  const storageData: string = (typeof value === 'object') ? JSON.stringify(value) : value;
  sessionStorage.setItem(key, storageData);
};

export const clearStorageData = (): void => sessionStorage.clear();

export const removeStorageDataByKey = (key: string): void => sessionStorage.removeItem(key);

export const getStorageData = (key: string): string | null => sessionStorage.getItem(key);

export const setLocalStorageDataItem = (key: string, value: any): void => {
  const storageData: string = (typeof value === 'object') ? JSON.stringify(value) : value;
  localStorage.setItem(key, storageData);
};

export const getLocalStorageData = (key: string): string | null => localStorage.getItem(key);

export const clearLocalStorageData = (): void => localStorage.clear();

export const clearLocalStoragePartly = (dataList: any[]): void => {
  Object.keys(localStorage).forEach((key: string) => {
    if (!dataList.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ERoutePaths } from 'enums';
import { IRootState } from 'store/types';
import { AuthorizeAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import * as commonActions from 'store/features/common/actions';

const ExternalLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { ssoConfig } = state.common?.appConfig || {};

  const clickHandler = () => {
    dispatch(commonActions.setLoading(true));
    AuthorizeAPI().then((response) => {
      if (response.data.url) {
        window.location.href = response.data.url;
      } else {
        navigate(ERoutePaths.root);
      }
    }).catch(() => {
      navigate(ERoutePaths.root);
    });
  }

  return (
    <div className="external-login-content">
      <div className="container">
        <div className="login-container">
          <div className="identity-container">
            <img src={ssoConfig?.logo} alt="logo" />
            <span className="identity-text">
              {t('login.sso.message')}
            </span>
            <div className="button-container">
              <SingleButton
                isActive={!state.common?.loading}
                isDisabled={state.common?.loading}
                onClick={clickHandler}
                textButton={'Login'}
                variant={BUTTON_STYLE.OUTLINE_SECONDARY}
                radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              />
              {state.common?.loading && <div className="loader-container"><Loader /></div>}
            </div>
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default ExternalLogin;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ISingleButtonProps } from 'types';
import { ReactComponent as Logo } from 'images/pxlCheck.svg';
import SwitchLanguage from 'components/lang/SwitchLanguage';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, LOCAL_STORAGE_LNG } from 'constants/Static';
import { clearLocalStoragePartly } from 'util/storageHelper';
import { ERoutePaths } from 'enums';
import { IRootState } from 'store/types';
import { AuthorizeAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import * as commonActions from 'store/features/common/actions';

const LogOut = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state: IRootState = useSelector((rootState: IRootState) => rootState);
  const { ssoConfig } = state.common?.appConfig || {};

  const loginClickHandler = () => {
    dispatch(commonActions.setLoading(true));
    clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
    if (ssoConfig) {
      AuthorizeAPI().then((response) => {
        if (response.request.responseURL) {
          window.location.href = response.request.responseURL;
        } else {
          navigate(ERoutePaths.root);
        }
      }).catch(() => {
        navigate(ERoutePaths.root);
      });
    } else {
      dispatch(commonActions.setLoading(false));
      navigate(ERoutePaths.root);
    }
  };

  const buttonPropsData: ISingleButtonProps = {
    isActive: true,
    onClick: loginClickHandler,
    textButton: 'login.btn.login',
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
  };

  if (state.common?.loading) return <div className="loader-container"> <Loader /></div>;

  return (
    <div className="main-content">
      <div className="container">
        <div className="logout-container">
          <div className="identity-container">
            <Logo />
            <span className="identity-text">{t('sign-in.header')}</span>
          </div>
          <div className='log-out-description-container'>
            <p> {t('logout.message1')}</p>
            <p> {t('logout.message2')}</p>
          </div>
          <div className="button-container">
            <SingleButton {...buttonPropsData} />
          </div>
        </div>
      </div>
      <SwitchLanguage />
    </div>
  );
};

export default LogOut;

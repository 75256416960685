import React from 'react';

import StyledText from 'components/styledText/StyledText';

const ListFromResources = ({ resource }: { resource: any }) => (
  <ul>
    {resource?.map((item: any, index: number) => (
      <li key={index} ><StyledText data={item} isTranslated={true} /></li>
    ))}
  </ul>
);

export default ListFromResources;

export enum EExtractedDataPriorityFields {
  'firstName',
  'lastName',
  'maidenName',
  'gender',
  'dateOfBirth',
  'nationality',
  'address',
  'documentCountry',
}

export enum EAddressPriorityFields {
  'firstName',
  'lastName',
  'address',
  'documentCountry',
}

export enum ESelfDeclarationPriorityFields {
  'firstName',
  'lastName',
  'maidenName',
  'gender',
  'street',
  'houseNumber',
  'addressLine2',
  'zipCode',
  'city',
  'country',
  'nationality',
  'birthdate',
  'email',
  'countryCode',
  'phone',
}

export enum EDirection {
  left = -1,
  right = 1,
}

export enum EReturnCodesCategories {
  blocking = 'blocking',
  retryAndStop = 'retryAndStop',
  suspectedFraud = 'suspectedFraud',
  retryAndContinue = 'retryAndContinue',
  ignore = 'ignore',
  manualCheck = 'manualCheck',
  special = 'special',
  unhandled = 'unhandled',
}

export enum EReturnCodes {
  EError_AddressNotVerified = 'EError_AddressNotVerified',
  EError_AddressServiceNotAccessible = 'EError_AddressServiceNotAccessible',
  EError_CameraFailure = 'EError_CameraFailure',
  EError_CameraFlash = 'EError_CameraFlash',
  EError_DocumentDataMismatch = 'EError_DocumentDataMismatch',
  EError_FaceCaptureGeneric = 'EError_FaceCaptureGeneric',
  EError_FaceCaptureIntegrityCheckFailed = 'EError_FaceCaptureIntegrityCheckFailed',
  EError_FaceFrameSimilarityFailed = 'EError_FaceFrameSimilarityFailed',
  EError_FaceLayoutValidation = 'EError_FaceLayoutValidation',
  EError_FaceLivenessFailed = 'EError_FaceLivenessFailed',
  EError_FaceLivenessGeneric = 'EError_FaceLivenessGeneric',
  EError_FaceNotRecognised = 'EError_FaceNotRecognised',
  EError_FaceNotVerified = 'EError_FaceNotVerified',
  EError_FunctionNotImplemented = 'EError_FunctionNotImplemented',
  EError_HologramDetectionFailed = 'EError_HologramDetectionFailed',
  EError_HologramDetectionGeneric = 'EError_HologramDetectionGeneric',
  EError_IdBadMrzFieldBirthDay = 'EError_IdBadMrzFieldBirthDay',
  EError_IdBadMrzFieldCompositCheckDigit = 'EError_IdBadMrzFieldCompositCheckDigit',
  EError_IdBadMrzFieldCountry = 'EError_IdBadMrzFieldCountry',
  EError_IdBadMrzFieldDocumentNumber = 'EError_IdBadMrzFieldDocumentNumber',
  EError_IdBadMrzFieldExpiryDay = 'EError_IdBadMrzFieldExpiryDay',
  EError_IdBadMrzFieldGender = 'EError_IdBadMrzFieldGender',
  EError_IdBadMrzFieldNationality = 'EError_IdBadMrzFieldNationality',
  EError_IdBadMrzFields = 'EError_IdBadMrzFields',
  EError_IdExpired = 'EError_IdExpired',
  EError_IdFaceImageCaptureFailed = 'EError_IdFaceImageCaptureFailed',
  EError_IdGeneric = 'EError_IdGeneric',
  EError_IdIncompleteData = 'EError_IdIncompleteData',
  EError_IdMatchingFailed = 'EError_IdMatchingFailed',
  EError_IdMatchingFailedMrz = 'EError_IdMatchingFailedMrz',
  EError_IdMismatch = 'EError_IdMismatch',
  EError_IdNeedSecondPageForMatching = 'EError_IdNeedSecondPageForMatching',
  EError_IdNoData = 'EError_IdNoData',
  EError_IdNotDetected = 'EError_IdNotDetected',
  EError_IdNotInList = 'EError_IdNotInList',
  EError_IdPageMissing = 'EError_IdPageMissing',
  EError_ImageAnomaly = 'EError_ImageAnomaly',
  EError_ImageBlured = 'EError_ImageBlured',
  EError_ImageDocumentTooFar = 'EError_ImageDocumentTooFar',
  EError_ImageFromScreen = 'EError_ImageFromScreen',
  EError_ImageGeneric = 'EError_ImageGeneric',
  EError_ImageInjection = 'EError_ImageInjection',
  EError_ImageIsGreyscale = 'EError_ImageIsGreyscale',
  EError_ImageUnsupportedResolution = 'EError_ImageUnsupportedResolution',
  EError_ImageUnsupportedType = 'EError_ImageUnsupportedType',
  EError_IncorrectInput = 'EError_IncorrectInput',
  EError_LenticularDetectionFailed = 'EError_LenticularDetectionFailed',
  EError_LenticularDetectionGeneric = 'EError_LenticularDetectionGeneric',
  EError_minimumAge = 'EError_minimumAge',
  EError_MRZ_NotDetected = 'EError_MRZ_NotDetected',
  EError_MrzLayoutValidation = 'EError_MrzLayoutValidation',
  EError_NFC_CertificateValidationFailed = 'EError_NFC_CertificateValidationFailed',
  EError_NFC_CLONED_CHIP = 'EError_NFC_CLONED_CHIP',
  EError_NFC_INVALID_HASHES = 'EError_NFC_INVALID_HASHES',
  EError_NFC_MRZ_DataMissing = 'EError_NFC_MRZ_DataMissing',
  // eslint-disable-next-line max-len
  EError_NFC_MutualAuthenticationFailedNotSatisfied = 'EError_NFC_MutualAuthenticationFailedNotSatisfied',
  EError_NFC_MutualAuthenticationFailedUnknown = 'EError_NFC_MutualAuthenticationFailedUnknown',
  EError_NFC_NotConnected = 'EError_NFC_NotConnected',
  EError_NFC_NotSupported = 'EError_NFC_NotSupported',
  EError_NFC_ReadFailed = 'EError_NFC_ReadFailed',
  EError_NFC_SOD_VS_COM_INSONSITENCY = 'EError_NFC_SOD_VS_COM_INSONSITENCY',
  EError_NFC_TagWasLost = 'EError_NFC_TagWasLost',
  EError_NFC_TechnicalError = 'EError_NFC_TechnicalError',
  EError_NFC_TEMPERED_DATA = 'EError_NFC_TEMPERED_DATA',
  EError_NFC_Timeout = 'EError_NFC_Timeout',
  EError_NFC_UnexpectedException = 'EError_NFC_UnexpectedException',
  EError_NFC_User_Skipped = 'EError_NFC_User_Skipped',
  EError_OptionalPageMissing = 'EError_OptionalPageMissing',
  EError_OptionalPageNotDetected = 'EError_OptionalPageNotDetected',
  EError_DeviceIntegrityCompromised = 'EError_DeviceIntegrityCompromised',
  EError_DocumentLivenessFailure = 'EError_DocumentLivenessFailure',
  EError_ProcessingTimeout = 'EError_ProcessingTimeout',
  EError_QrCodeBadData = 'EError_QrCodeBadData',
  EError_QrCodeGeneric = 'EError_QrCodeGeneric',
  EError_QrCodeTimeoutExpired = 'EError_QrCodeTimeoutExpired',
  EError_Reflection = 'EError_Reflection',
  EError_SecurityFeaturesGeneric = 'EError_SecurityFeaturesGeneric',
  EError_ServerNotAccessible = 'EError_ServerNotAccessible',
  EError_ServerTimeout = 'EError_ServerTimeout',
  EError_SignDocGeneric = 'EError_SignDocGeneric',
  EError_SpecimenDetection = 'EError_SpecimenDetection',
  EError_ThirdPartyLibFailure = 'EError_ThirdPartyLibFailure',
  EError_ThirdPartyLibLicenceExpired = 'EError_ThirdPartyLibLicenceExpired',
  EError_UnsupportedCameraResolution = 'EError_UnsupportedCameraResolution',
  EError_UnsupportedVideoConfiguration = 'EError_UnsupportedVideoConfiguration',
  InputValidationError = 'InputValidationError',
  InvalidRequest = 'InvalidRequest',
  SignatureRequestCreationFailed = 'SignatureRequestCreationFailed',
  SignatureRequestError = 'SignatureRequestError',
  VideoProcessingTimeOut = 'VideoProcessingTimeOut',
  EError_ImageOrVideoSignatureMismatch = 'EError_ImageOrVideoSignatureMismatch',
}

export enum EDocumentType {
  id = 'id',
  passport = 'passport',
  paperPermit = 'paper-permit',
  plasticPermit = 'plastic-permit',
  drivingLicence = 'drivingLicence',
  healthCard = 'healthCard',
  something = 'something',
}

export enum ECountry {
  che = 'CHE',
  can = 'CAN',
  usa = 'USA',
}

export enum EImageSide {
  front = 'front',
  back = 'back',
  signature = 'signature',
  other = 'other',
  empty = '',
}

export enum EDrivingCategories {
  m = 'M',
  minusA = '-A',
  a1 = 'A1',
  a = 'A',
  b = 'B',
  b1 = 'B1',
  be = 'BE',
  d1 = 'D1',
  d1e = 'D1E',
  d = 'D',
  de = 'DE',
  c1 = 'C1',
  c1e = 'C1E',
  c = 'C',
  ce = 'CE',
  f = 'F',
  g = 'G',
  czv = 'CZV',
  btp = 'BTP',
}

export enum ETransactionState {
  completed = 'COMPLETED',
  inReview = 'IN_REVIEW',
  reviewed = 'REVIEWED',
  rejected = 'REJECTED',
  needReview = 'NEED_REVIEW',
  deleted = 'DELETED',
  notFound = 'NOT_FOUND',
  inError = 'IN_ERROR',
  checkCompleted = 'CHECK_COMPLETED',
  pxlCheckError = 'PXL_CHECK_ERROR',
}

export enum EHttpStatusCode {
  notFound = 404,
  conflict = 409,
  unauthorized = 401,
  forbidden = 403,
  internalServerError = 500,
}

export enum EImageName {
  firstImage = 'firstImage',
  secondImage = 'secondImage',
  fullFrameFirstPage = 'fullFrameFirstPage',
  fullFrameSecondPage = 'fullFrameSecondPage',
}

export enum ETools {
  zoomIn = 'zoomIn',
  zoomOut = 'zoomOut',
  rotate = 'rotate',
  images = 'images',
  highLite = 'highLite',
}

export enum EUnits {
  pixel = 'px',
  percentage = '%',
}

export enum ERoutePaths {
  root = '/',
  signIn = '/sign-in',
  forgottenPassword = '/forgotten-password',
  setPassword = '/set-password',
  logOut = '/log-out',
  dashboard = '/dashboard',
  taBoarding = '/ta-boarding',
  identityReview = '/identity-review',
  addressReview = '/address-review',
  a4scanReview = '/a4scan-review',
}

export enum EReviewStates {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum EFileTypes {
  firstImage = 'firstImage',
  secondImage = 'secondImage',
  faceImage = 'faceImage',
  fullFrameFirstPage = 'fullFrameFirstPage',
  fullFrameSecondPage = 'fullFrameSecondPage',
  signatureImage = 'signatureImage',
  firstVideo = 'firstVideo',
  secondVideo = 'secondVideo',
  faceAvatar = 'faceAvatar',
  selfieVideo = 'selfieVideo',
  addressDocument = 'addressDocument',
  addressCrop = 'addressCrop',
  a4Scan = 'a4Scan',
}

export enum EZones {
  viz = 'viz',
  mrz = 'mrz',
  nfc = 'nfc',
  barcode = 'barcode',
}

export enum EAnswers {
  yes = 'yes',
  no = 'no',
}

export enum EStatuses {
  pass = 'pass',
  fail = 'fail',
}

export enum EFields {
  firstName = 'firstName',
  lastName = 'lastName',
  dateOfBirth = 'dateOfBirth',
  documentType = 'documentType',
  documentNumber = 'documentNumber',
  documentCountry = 'documentCountry',
  nationality = 'nationality',
  gender = 'gender',
  expirationDate = 'expirationDate',
  dateOfIssue = 'dateOfIssue',
  authority = 'authority',
  address = 'address',
  drivingCategories = 'drivingCategories',
  endorsements = 'endorsements',
  restrictions = 'restrictions',
  fullName = 'fullName',
  documentSubtype = 'documentSubtype',
  maidenName = 'maidenName',
  placeOfBirth = 'placeOfBirth',
  zemisNumber = 'zemisNumber',
  drivingCategory = 'drivingCategory',
  dateOfEntry = 'dateOfEntry',
  overallRestriction = 'overallRestriction',
  marriedName = 'marriedName',
  kantonReferenceNumber = 'kantonReferenceNumber',
  personalIdentificationNumber = 'personalIdentificationNumber',
  institutionNumber = 'institutionNumber',
}

export enum EDrivingCategoryFields {
  value = 'value',
  expiryDate = 'expiryDate',
  issuingDate = 'issuingDate',
  restrictions = 'restrictions',
}

export enum ESearchType {
  transactionCode = 'transactionCode',
  orderId = 'orderId',
}

export const enum ESelectTypes {
  documentCountry = 'documentCountry',
  documentType = 'documentType',
  extraDetail = 'extraDetail',
}

export enum EWorkflowModules {
  userValidation = 'userValidation',
  selfDeclaration = 'selfDeclaration',
  documentScan = 'documentScan',
  addressCheck = 'addressCheck',
  idScan = 'idScan',
  partnerQes = 'partnerQes',
  nfcDetection = 'nfcDetection',
  faceVerification = 'faceVerification',
  securityFeatures = 'securityFeatures',
  livenessDetection = 'livenessDetection',
}

export enum EAcceptanceCheckNames {
  documentValid = 'documentValid',
  documentPresented = 'documentPresented',
  documentPhysicalSigns = 'documentPhysicalSigns',
  documentClearlyVisible = 'documentClearlyVisible',
  faceVerificationPhysicalSigns = 'faceVerificationPhysicalSigns',
  faceVerificationSamePerson = 'faceVerificationSamePerson',
  livenessRealVideo = 'livenessRealVideo',
}
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Turnstile, { useTurnstile } from 'react-turnstile';
import { useTranslation } from 'react-i18next';

import { IRootState } from 'store/types';

const TurnstileWidget = (props: {
  setToken: Function,
  setErrorMessage: Function,
  turnstileToken: string,
}) => {
  const { setToken, turnstileToken, setErrorMessage } = props;
  const { i18n } = useTranslation();
  const turnstile = useTurnstile();
  const state = useSelector((rootState: IRootState) => rootState);
  const { siteKey = '' } = state.common?.appConfig || {};

  useEffect(() => {
    if (!siteKey) window.location.reload();
    if (!turnstileToken) turnstile?.reset();
  }, [turnstileToken, siteKey]);

  return (
    <Turnstile
      sitekey={siteKey}
      language={i18n.language}
      onVerify={(token) => setToken(token)}
      onError={() => setErrorMessage('messages.invalid-request')}
      appearance={'interaction-only'}
    />
  );
}

export default TurnstileWidget;
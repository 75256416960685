import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DatePicker from 'components/datePicker/DatePicker';
import ToolTip from 'components/toolTipField/ToolTipField';
import { EFields, EZones } from 'enums';
import { TFields, TFieldData } from 'store/features/reviewResult/types';
import { fieldValueValidated } from 'helper/validation';
import { isFieldCopyAllowed } from 'helper';

interface ISelectorProps {
  zones: Array<EZones>;
  fieldKey: keyof TFields;
  fieldData?: TFieldData;
  setPersonalInfo: (newValues: TFieldData) => void;
}

const FieldComparisonDatePicker = (props: ISelectorProps) => {
  const { zones, fieldKey, fieldData, setPersonalInfo } = props;
  const { viz } = fieldData || {};
  const { t } = useTranslation();

  const detectedFieldType = useMemo(
    () => zones.find((zone) => zone !== EZones.viz),
    [zones]
  ) as keyof typeof EZones;

  const { extractedValue, editable = true, disabled = false, optional = false } = viz || {};
  let { value: vizValue = '' } = viz || {};
  if (!editable) {
    vizValue = t('identity.doc.data.not-applicable');
  }

  const {
    extractedValue: detectedValueIdentifier = '',
    editable: detectedFieldEditable = true,
    disabled: detectedFieldDisabled = false,
    optional: detectedFieldOptional = false,
  } = fieldData?.[detectedFieldType] ?? {};
  let { value: detectedValue = '' } = fieldData?.[detectedFieldType] || {};
  if (!detectedFieldEditable) {
    detectedValue = t('identity.doc.data.not-applicable');
  }

  const [vizCopied, setVizCopied] = useState(extractedValue !== vizValue);
  const [detectedCopied, setDetectedCopied] = useState(
    detectedValueIdentifier !== detectedValue
  );

  const allowToCopyVizValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones.viz,
      detectedValueIdentifier,
      optional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      disabled,
      detectedValue,
      vizValue
    );
  }, [
    detectedValue,
    editable,
    detectedValueIdentifier,
    detectedFieldEditable,
    disabled,
    optional,
    vizValue
  ]);

  const allowToCopyDetectedValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones[detectedFieldType],
      vizValue,
      detectedFieldOptional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      detectedFieldDisabled,
      vizValue,
      detectedValue
    );
  }, [
    detectedFieldType,
    detectedFieldEditable,
    detectedFieldOptional,
    editable,
    detectedFieldDisabled,
    detectedValue,
    vizValue
  ]);

  const handleDatePickerChange = (
    value: string,
    zoneType: EZones,
  ) => {
    const valid = fieldValueValidated(
      EFields[fieldKey],
      zoneType,
      value,
      fieldData?.[zoneType]?.optional || false
    );
    if (valid) {
      setPersonalInfo({
        [zoneType]: {
          ...fieldData?.[zoneType],
          value: value,
        },
      });
      if (zoneType === EZones.viz) {
        setVizCopied(value !== extractedValue);
      } else {
        setDetectedCopied(value !== detectedValueIdentifier);
      }
    }
  };

  const copyValue = (fromVizToDetected: boolean) => {
    const sourceValue = fromVizToDetected ? vizValue : detectedValue;
    const targetZone = fromVizToDetected ? detectedFieldType : EZones.viz;
    const valid = fieldValueValidated(
      EFields[fieldKey],
      EZones[targetZone],
      sourceValue,
      fromVizToDetected ? detectedFieldOptional : optional
    );
    if (valid) {
      setPersonalInfo({
        [targetZone]: {
          ...fieldData?.[targetZone],
          value: sourceValue,
        },
      });
      if (fromVizToDetected) {
        setDetectedCopied(true);
      } else {
        setVizCopied(true);
      }
    }
  };

  switch (zones.length) {
  case 1:
    return (
      <>
        <div className='custom-comparison-input-container'>
          <ToolTip placement='top' tooltipValue={t(`identity.doc.data.${fieldKey}`)} >
            <span className='custom-input-field-key'>{t(`identity.doc.data.${fieldKey}`)}: </span>
          </ToolTip>
          <div className='custom-input-container'>
            <DatePicker
              setFieldValue={(value: string) =>
                handleDatePickerChange(value, EZones.viz)
              }
              fieldValue={vizValue}
              field={fieldKey}
              validated={fieldValueValidated(
                EFields[fieldKey],
                EZones.viz,
                detectedValue,
                optional
              )}
              disabled={!viz?.editable}
              autoFocus={false}
            />
          </div>
        </div>
      </>
    );
  default:
    return (
      <>
        <div className='custom-comparison-input-container'>
          <ToolTip placement='top' tooltipValue={t(`identity.doc.data.${fieldKey}`)} >
            <span className='custom-input-field-key'>{t(`identity.doc.data.${fieldKey}`)}: </span>
          </ToolTip>
          <div className='custom-input-container'>
            {editable ? (
              <DatePicker
                setFieldValue={(value: string) =>
                  handleDatePickerChange(value, EZones.viz)
                }
                fieldValue={vizValue}
                field={fieldKey}
                validated={fieldValueValidated(
                  EFields[fieldKey],
                  EZones.viz,
                  detectedValue,
                  optional
                )}
                disabled={viz?.disabled}
                autoFocus={false}
                className={`
                  ${detectedFieldEditable && detectedValue !== vizValue ? 'mismatch' : ''}
                  ${vizCopied ? 'copied' : ''}
                  ${!viz?.editable ? 'not-editable' : ''}`}
              />) : (
              <input
                className='custom-input not-editable'
                disabled
                value={vizValue}
              />
            )}
            <button
              onClick={() => copyValue(false)}
              className={allowToCopyVizValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyVizValue}
            >
              &#x276E;
            </button>
          </div>
          <div className='custom-input-container'>
            <button
              onClick={() => copyValue(true)}
              className={allowToCopyDetectedValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyDetectedValue}
            >
              &#x276F;
            </button>
            {detectedFieldEditable ? (
              <DatePicker
                setFieldValue={(value: string) =>
                  handleDatePickerChange(
                    value,
                    EZones[detectedFieldType],
                  )
                }
                fieldValue={detectedValue}
                field={fieldKey}
                validated={fieldValueValidated(
                  EFields[fieldKey],
                  EZones[detectedFieldType],
                  vizValue,
                  detectedFieldOptional
                )}
                className={`
                  ${viz?.editable && detectedValue !== vizValue ? 'mismatch' : ''}
                  ${detectedCopied ? 'copied' : ''}
                  ${!detectedFieldEditable ? 'not-editable' : ''}`}
                disabled={fieldData?.[detectedFieldType]?.disabled}
                autoFocus={false}
              />) : (
              <input
                className='custom-input not-editable'
                disabled
                value={detectedValue}
              />
            )}
          </div>
        </div>
      </>
    );
  }
};

export default FieldComparisonDatePicker;

import { getStorageData } from 'util/storageHelper';
import { IReviewResult } from 'store/features/reviewResult/types';

export default (): IReviewResult => {
  const data: string | null = getStorageData('state');
  const storageData = data ? JSON.parse(data) : {};
  return {
    transactionCode: storageData.transactionCode || '',
    transactionData: storageData.transactionData || null,
    manualCheckResult: storageData.manualCheckResult || {
      rejectionReasons: {
        a4ScanReview: [],
        addressReview: [],
        document: [],
        documentRecording: [],
        faceVerification: [],
        imageQuality: [],
        livenessDetection: [],
      },
      identityReview: {
        documentValidationReview: [{
          result: '',
        }],
        faceVerificationReview: {
          result: '',
        },
        livenessDetectionReview: {
          result: '',
        },
      },
      addressReview: {
        result: '',
      },
      a4ScanReview: {
        result: '',
      },
      acceptanceChecks: {
        documentValid: false,
        documentPresented: false,
        documentPhysicalSigns: false,
        documentClearlyVisible: false,
        livenessRealVideo: false,
        faceVerificationPhysicalSigns: false,
        faceVerificationSamePerson: false,
      },
    },
  };
};

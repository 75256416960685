import React from 'react';
import { useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';

import LeftMenu from 'components/menuSide/LeftMenu';

const BurgerMenu = (props: { showInstructions: boolean }) => {
  const location = useLocation();

  return (
    <div className='burger-menu-main-component'>
      <Menu
        isOpen={props.showInstructions}
        right
        customBurgerIcon={false}
      >
        <LeftMenu pathName={location.pathname} />
      </Menu>
    </div>
  );
};

export default BurgerMenu;

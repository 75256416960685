import { EFields, EZones } from 'enums';
import { TActiveTransaction, TReviewedTransaction } from 'types';

export enum CommonActions {
  setDocRequiredFields = 'SET_DOC_REQUIRED_FIELDS',
  setDefaultTransactionCode = 'SET_DEFAULT_TRANSACTION_CODE',
  setDashboardData = 'SET_DASHBOARD_DATA',
  setLoading = 'SET_LOADING',
  setAppConfig = 'SET_APP_CONFIG',
}

export type TDashboard = {
  items: Array<TActiveTransaction | TReviewedTransaction>;
  page: number;
  size: number;
  totalItems: number;
  active: boolean;
}

export interface IBrand {
  name: string;
  title: string;
}
export interface IUrls {
  instruction: IInstruction;
}

export interface IInstruction {
  en: string;
  de: string;
}
export interface ILang {
  switchable: boolean;
  langs: Array<string>;
  default: string;
}

export type TAppConfig = {
  urls: IUrls;
  lang: ILang;
  dashboardTableRowCount: number;
  siteKey: string;
  version: string;
  ssoConfig?: {
    logo: string;
  }
}

export type TRequiredZone = {
  [zone in EZones]: {
    optional: boolean;
  }
}

export type TRequiredFields = {
  [field in EFields]?: TRequiredZone
};

export interface ICommon {
  docRequiredFields: TRequiredFields;
  defaultTransactionCode: string | undefined;
  dashboard: TDashboard;
  appConfig: TAppConfig;
  loading: boolean;
}

export type TCommonActions =
  { type: CommonActions.setDocRequiredFields; value: TRequiredFields } |
  { type: CommonActions.setDefaultTransactionCode; value: string } |
  { type: CommonActions.setDashboardData; value: TDashboard } |
  { type: CommonActions.setLoading; value: boolean } |
  { type: CommonActions.setAppConfig; value: TAppConfig };

import produce from 'immer';

import { setLocalStorageDataItem } from 'util/storageHelper';
import { AgentActions, TAgent, TAgentActions } from 'store/features/agentManagement/types';
import getInitialState from 'store/features/agentManagement/initialState';

export const agentReducer = (state: TAgent = getInitialState(), action: TAgentActions) => {
  const nextState = produce(state, (draftState: TAgent) => {
    const { type, value } = action;
    switch (type) {
    case AgentActions.setAgent:
      draftState = value;
      setLocalStorageDataItem('agent', draftState);
      break;
    }
    return draftState;
  });
  return nextState;
};

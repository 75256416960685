import { AxiosResponse } from 'axios';
import React, { useRef, useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { ReactComponent as ZoomIn } from 'images/zoom-in.svg';
import { ReactComponent as ZoomOut } from 'images/zoom-out.svg';
import { getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { useTranslation } from 'react-i18next';
import { getNotFoundImage } from 'helper';
import { GetResourceAPI } from 'helper/api/route';

interface IImageWrapper {
  scale?: number;
  transactionCode: string;
  filetype?: string;
  path?: string;
  enableZoom?: boolean;
  image?: string;
  setImageType?: (data: string) => void;
  setImages?: (data: string) => void;
}

const ImageWrapper = (props: IImageWrapper) => {
  const { i18n } = useTranslation();
  const { scale = 1, transactionCode, filetype, setImageType, enableZoom = false, setImages, image } = props;
  const containerRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const [documentImage, setDocumentImage] = useState('');
  const [resourceData, setResourceData] = useState({
    documentImageLoaded: false,
    documentImageUnavailable: false,
  });

  const setupZoom = (rest: any) => {
    rest.doubleClick.disabled = true;
    rest.pan.paddingSize = 0;
    rest.options.minScale = 1;
    rest.options.maxScale = 3;
  };

  const onLoad = (setPositionX: Function, setPositionY: Function, setDefaultState: Function) => {
    setDefaultState();
    setPositionX(0, 0, 'easeOut');
    setPositionY(0, 0, 'easeOut');
  };

  useEffect(() => {
    if (image) {
      setDocumentImage(image);
      setResourceData((prevState) => ({
        ...prevState,
        documentImageLoaded: true,
      }));
    } else if (!filetype) {
      setResourceData((prevState) => ({
        ...prevState,
        documentImageLoaded: true,
        documentImageUnavailable: true,
      }));
    } else {
      GetResourceAPI(transactionCode, filetype)
        .then((response) => {
          const res = response as AxiosResponse<{ content: string }>;
          if (res.data) {
            setDocumentImage(res.data.content);
            setImages && setImages(res.data.content);
            setResourceData((prevState) => ({
              ...prevState,
              documentImageLoaded: true,
            }));
          }
        }).catch(() => {
          setResourceData((prevState) => ({
            ...prevState,
            documentImageLoaded: true,
            documentImageUnavailable: true,
          }));
        });
    }
  }, [image]);

  const getDocumentImage = () => {
    if (resourceData.documentImageUnavailable) {
      return getNotFoundImage(i18n.language);
    }
    return getImageFromBase64(documentImage);
  }

  return (<>
    <div className="wrapper-transform-component">
      <TransformWrapper
        zoomIn={{
          step: 15
        }}
        zoomOut={{
          step: 15
        }}
        defaultScale={1} scale={scale}>
        {({
          zoomIn,
          zoomOut,
          wheel,
          setScale,
          setPositionX,
          setPositionY,
          setDefaultState,
          positionX,
          positionY,
          ...rest
        }: any) => {
          wheel.disabled = true;
          setupZoom(rest);
          const disabledZoomIn = (rest.scale === rest.options.maxScale) ? 'is-disabled' : '';
          const disabledZoomOut = (rest.scale === rest.options.minScale) ? 'is-disabled': '';
          return (
            <div className="transform-component">
              <div className="wrapper-image-container" ref={containerRef}>
                {!resourceData.documentImageLoaded && <div className="loader-container"><Loader /></div>}
                <TransformComponent
                >
                  <button
                    className='transform-image-button'
                    onClick={() => {
                      if (setImageType) setImageType((documentImage && filetype) || '');
                    }}>
                    <img
                      src={getDocumentImage()}
                      onLoad={() => onLoad(setPositionX, setPositionY, setDefaultState)}
                      loading="lazy"
                      alt="transform-component"
                    />
                  </button>
                </TransformComponent>
              </div>
              {enableZoom && <div className="tools">
                {
                  enableZoom && <div
                    className={`zoom ${disabledZoomOut}`}
                    onClick={zoomOut}
                  >
                    <ZoomOut />
                  </div>
                }
                {
                  enableZoom && <div
                    className={`zoom ${disabledZoomIn}`}
                    onClick={zoomIn}
                  >
                    <ZoomIn />
                  </div>
                }
              </div>
              }
            </div>
          );
        }}
      </TransformWrapper>
    </div>
  </>);
};

export default ImageWrapper;
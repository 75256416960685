import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { batchActions } from 'redux-batched-actions';

import {
  GetTransactionDataAPI,
  GetTransactionsAPI,
  LockTransactionAPI,
  LogErrorAPI,
  UnlockTransactionAPI,
} from 'helper/api/route';
import SwitchDatasetsModal from 'pages/dashboard/Modals/SwitchDatasets';
import SearchTransactionModal from 'pages/dashboard/Modals/SearchTransaction';
import ErrorMessagesModal from 'pages/dashboard/Modals/ErrorMessages';
import LockTransaction from 'pages/dashboard/LockTransaction';
import * as commonActions from 'store/features/common/actions';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { IRootState } from 'store/types';
import { IStyledText, TActiveTransaction, TGetTransactionOptions, TLockTransaction, TReviewedTransaction } from 'types';
import { convertTimestampToDate, getErrorMessage, getExtractedData, getSelectedDataset, toTitleCase } from 'helper';
import TransactionsTable from 'pages/dashboard/Table/Transactions';
import { ERoutePaths, ESearchType } from 'enums';

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { dashboard } = state?.common || {};
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSearchModalShowing, setSearchModalIsShowing] = useState(false);
  const [isSwitchModalShowing, setSwitchModalIsShowing] = useState(false);
  const [isErrorModalShowing, setErrorModalIsShowing] = useState(false);
  const [searchType, setSearchType] = useState(ESearchType.transactionCode);
  const [message, setMessage] = useState<IStyledText[]>([]);
  const defaultTransactionCode = state.common?.defaultTransactionCode || '';
  const datasetId = getSelectedDataset(state.agent?.datasets)?.id;

  useEffect(() => {
    if (defaultTransactionCode) {
      startReview({ transactionCode: defaultTransactionCode, orderId: '' });
    }
  } , [defaultTransactionCode]);

  const getTransactions = (options?: TGetTransactionOptions) => {
    const page = options?.specificPage || dashboard?.page || 1;
    const limit = state.common?.appConfig?.dashboardTableRowCount || 20;
    const activeState = options?.active !== undefined ? options.active : dashboard?.active;
    dispatch(commonActions.setLoading(true));
    const params = {
      limit: String(limit),
      offset: String(page === 1 ? 0 : (page - 1) * limit),
      reviewed: activeState ? 'false': 'true',
      datasetId: String(datasetId),
    };
    GetTransactionsAPI(params).then((response) => {
      const { data: responseData } = response;
      const { items } = responseData;
      const formattedItems = items?.map((item: TActiveTransaction | TReviewedTransaction) => {
        return {
          ...item,
          transactionState: toTitleCase(item.transactionState),
          completedTime: convertTimestampToDate(item.completedTime),
          ...('reviewedTime' in item && { reviewedTime: convertTimestampToDate(item.reviewedTime) }),
        };
      });
      if (dashboard) {
        dispatch(commonActions.setDashboardData({
          ...dashboard,
          active: !!activeState,
          page,
          items: formattedItems,
          totalItems: responseData.totalItems,
          size: limit,
        }));
      }
    }).catch((error) => {
      LogErrorAPI(error);
      if (dashboard) {
        dispatch(commonActions.setDashboardData({ ...dashboard, items: [] }));
      }
    }).finally(() => dispatch(commonActions.setLoading(false)));
  };

  const startReview = ({ transactionCode, orderId }: TLockTransaction) => {
    setIsWaiting(true);
    dispatch(commonActions.setDefaultTransactionCode(''));
    LockTransactionAPI({ transactionCode, orderId }).then((response) => {
      const { transactionCode } = response.data;
      GetTransactionDataAPI(transactionCode).then((response) => {
        const {
          documentCountry, documentType, extraDetail, classifiedDocument
        } = getExtractedData(response.data.documentVerification);
        batchActions([
          dispatch(reviewResultActions.setTransactionData({
            transactionData: response.data,
            transactionCode,
          })),
          dispatch(reviewResultActions.setDocumentValidation({
            documentCountry,
            documentType,
            extraDetail,
            classifiedDocument,
          })),
        ])
        setIsWaiting(false);
        navigate(`${ERoutePaths.taBoarding}/${transactionCode}`);
      }).catch(() => {
        setIsWaiting(false);
        setMessage([
          { text: t('messages.inError') },
          { text: transactionCode, type: 'bold' },
          { text: t('messages.notAvailable') },
        ]);
        setErrorModalIsShowing(true);
        UnlockTransactionAPI(transactionCode);
      });
    }).catch((error) => {
      const state = error?.response?.data?.message || '';
      const errorMessage = getErrorMessage(state, transactionCode || '');
      setMessage(errorMessage);
      setErrorModalIsShowing(true);
      setIsWaiting(false);
    });
  };

  const modalIsShowing = isSearchModalShowing || isSwitchModalShowing || isErrorModalShowing;

  return (
    <div className="dashboard">
      <div className="dashboard-container">
        <div
          className={`overlap-area ${!modalIsShowing ? 'overlap_disable' : ''}`}
        />
        {isSearchModalShowing && (
          <SearchTransactionModal
            close={setSearchModalIsShowing}
            startReview={startReview}
            searchType={searchType}
          />
        )}
        {isSwitchModalShowing && (
          <SwitchDatasetsModal close={setSwitchModalIsShowing} />
        )}
        {isErrorModalShowing && (
          <ErrorMessagesModal
            close={setErrorModalIsShowing}
            message={message}
          />
        )}
        <LockTransaction
          setSearchModalIsShowing={setSearchModalIsShowing}
          setSearchType={setSearchType}
          setSwitchModalIsShowing={setSwitchModalIsShowing}
          isWaiting={isWaiting}
          onRefresh={getTransactions}
          startReview={startReview}
        />
        <TransactionsTable getTransactions={getTransactions}/>
      </div>
    </div>
  );
};

export default Dashboard;

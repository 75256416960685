import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SelectOption from 'components/select/SelectOption';
import ToolTip from 'components/toolTipField/ToolTipField';
import { IOptionType } from 'types';
import { EFields, EZones } from 'enums';
import { TFields, TFieldData } from 'store/features/reviewResult/types';
import { fieldValueValidated } from 'helper/validation';
import { areValuesEqual, isFieldCopyAllowed } from 'helper';

interface ISelectorProps {
  zones: Array<EZones>;
  fieldKey: keyof TFields;
  fieldData?: TFieldData;
  setPersonalInfo: (newValues: TFieldData) => void;
  options: Array<IOptionType>;
}

const FieldComparisonSelector = (props: ISelectorProps) => {
  const { options, zones, fieldKey, fieldData, setPersonalInfo } = props;
  const { viz } = fieldData || {};
  const { t } = useTranslation();

  const detectedFieldType = useMemo(() => zones.find((zone) => zone !== EZones.viz), [zones]) as keyof typeof EZones;

  const { extractedValue = '', editable = true, disabled = false, optional = false } = viz || {};
  let { value: vizValue = '' } = viz || {};
  if (!editable) {
    vizValue = t('identity.doc.data.not-applicable');
  }

  const {
    extractedValue: detectedValueIdentifier = '',
    editable: detectedFieldEditable = true,
    disabled: detectedFieldDisabled = false,
    optional: detectedFieldOptional = false,
  } = fieldData?.[detectedFieldType] ?? {};
  let { value: detectedValue = '' } = fieldData?.[detectedFieldType] || {};
  if (!detectedFieldEditable) {
    detectedValue = t('identity.doc.data.not-applicable');
  }

  const [vizCopied, setVizCopied] = useState(!areValuesEqual(extractedValue, vizValue));
  const [detectedCopied, setDetectedCopied] = useState(!areValuesEqual(detectedValueIdentifier, detectedValue));

  const allowToCopyVizValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones.viz,
      detectedValueIdentifier,
      optional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      disabled,
      detectedValue,
      vizValue
    );
  }, [
    detectedValue,
    detectedValueIdentifier,
    editable,
    detectedFieldEditable,
    disabled,
    optional,
    vizValue
  ]);

  const allowToCopyDetectedValue = useMemo(() => {
    const isValid = fieldValueValidated(
      EFields[fieldKey],
      EZones[detectedFieldType],
      vizValue,
      detectedFieldOptional
    );

    return isFieldCopyAllowed(
      isValid,
      editable,
      detectedFieldEditable,
      detectedFieldDisabled,
      vizValue,
      detectedValue
    );
  }, [
    detectedFieldType,
    detectedFieldOptional,
    detectedFieldEditable,
    editable,
    detectedFieldDisabled,
    detectedValue,
    vizValue
  ]);

  const handleSelectChange = (
    selectedValue: string,
    zoneType: EZones,
  ) => {
    const valid = fieldValueValidated(
      EFields[fieldKey],
      zoneType,
      selectedValue,
      fieldData?.[zoneType]?.optional || false
    );
    if (valid) {
      setPersonalInfo({
        [zoneType]: {
          ...fieldData?.[zoneType],
          value: selectedValue,
        },
      });
      if (zoneType === EZones.viz) {
        setVizCopied(!areValuesEqual(extractedValue, selectedValue));
      } else {
        setDetectedCopied(!areValuesEqual(detectedValueIdentifier, selectedValue));
      }
    }
  };

  const copyValue = (fromVizToDetected: boolean) => {
    const sourceValue = fromVizToDetected ? vizValue : detectedValue;
    const targetZone = fromVizToDetected ? detectedFieldType : EZones.viz;
    const valid = fieldValueValidated(
      EFields[fieldKey],
      EZones[targetZone],
      sourceValue,
      fromVizToDetected ? detectedFieldOptional : optional
    );
    if (valid) {
      setPersonalInfo({
        [targetZone]: {
          ...fieldData?.[targetZone],
          value: sourceValue,
        },
      });
      if (fromVizToDetected) {
        setDetectedCopied(true);
      } else {
        setVizCopied(true);
      }
    }
  };

  const getCustomStyles = (
    value?: string,
    detectedValue?: string,
    copied?: boolean,
    disabled?: boolean,
    editable?: boolean,
  ) => {
    return {
      customStyles: {
        dropdownIndicator: (base: any) => ({
          ...base,
          color: '#d0cccc',
          '&:hover': {
            color: '#d0cccc',
          },
        }),
        indicatorsContainer: (base: any) => ({
          ...base,
          height: '100%',
          display: 'none',
        }),
        indicatorSeparator: (provided: any) => ({
          ...provided,
          pointerEvents: 'none',
          display: 'none',
          backgroundColor: 'unset',
        }),
        menu: (provided: any) => ({
          ...provided,
          borderRadius: '0',
        }),
        menuList: (base: any) => ({
          ...base,
          borderRadius: '0',
        }),
        control: (base: any) => ({
          ...base,
          minHeight: '100%',
          borderRadius: '0',
          boxShadow: 'none',
          border: '1px solid #d0cccc',
          backgroundColor: disabled ? '#e7e7e7' : copied ? '#ffcc40' : '#fff',
        }),
        placeholder: (base: any) => ({
          ...base,
          fontSize: '0.875rem',
          color: disabled ? '#000' : editable && (!areValuesEqual(value = '', detectedValue = '')) ? 'red' : '#064',
        }),
      },
    };
  };

  switch (zones.length) {
  case 1:
    return (
      <>
        <div className='custom-comparison-input-container'>
          <ToolTip placement='top' tooltipValue={t(`identity.doc.data.${fieldKey}`)} >
            <span className='custom-input-field-key'>{t(`identity.doc.data.${fieldKey}`)}: </span>
          </ToolTip>
          <div className='custom-input-container'>
            <SelectOption
              handler={(selected: IOptionType) => handleSelectChange(selected.value, EZones.viz)}
              placeholder={vizValue}
              options={options}
              value={vizValue}
              disabled={viz?.disabled}
              isSearchable={viz?.editable}
              customStylesSelect={getCustomStyles()}
            />
          </div>
        </div>
      </>
    );
  default:
    return (
      <>
        <div className='custom-comparison-input-container'>
          <ToolTip placement='top' tooltipValue={t(`identity.doc.data.${fieldKey}`)} >
            <span className='custom-input-field-key'>{t(`identity.doc.data.${fieldKey}`)}: </span>
          </ToolTip>
          <div className='custom-input-container'>
            <SelectOption
              handler={(selected: IOptionType) => handleSelectChange(selected.value, EZones.viz)}
              placeholder={vizValue}
              options={options}
              value={vizValue}
              disabled={disabled}
              isSearchable={editable}
              customStylesSelect={getCustomStyles(
                vizValue,
                detectedValue,
                vizCopied,
                disabled,
                detectedFieldEditable,
              )}
            />
            <button
              onClick={() => copyValue(false)}
              className={allowToCopyVizValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyVizValue}
            >&#x276E;</button>
          </div>
          <div className='custom-input-container'>
            <button
              onClick={() => copyValue(true)}
              className={allowToCopyDetectedValue ? 'copy-allowed' : ''}
              disabled={!allowToCopyDetectedValue}
            >&#x276F;</button>
            <SelectOption
              handler={(selected: IOptionType) => handleSelectChange(
                selected.value,
                EZones[detectedFieldType])
              }
              placeholder={detectedValue}
              options={options}
              value={detectedValue}
              disabled={fieldData?.[detectedFieldType]?.disabled}
              isSearchable={fieldData?.[detectedFieldType]?.editable}
              customStylesSelect={getCustomStyles(
                detectedValue,
                vizValue,
                detectedCopied,
                fieldData?.[detectedFieldType]?.disabled,
                editable,
              )}
            />
          </div>
        </div>
      </>
    );
  }
};

export default FieldComparisonSelector;

import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as CopyLogo } from 'images/copy.svg';
import { ReactComponent as CloseLogo } from 'images/close.svg';
import { KEY_CODE } from 'constants/Static';
import { ERoutePaths } from 'enums';
import { IRootState } from 'store/types';

type TTab = {
  name: string;
  url: string;
}

const showMessageTimeout = 1000;
const copyCode = (code: string, toggle: Function) => {
  copy(code);
  toggle(true);
  setTimeout(() => toggle(false), showMessageTimeout);
};

const tabs = [
  { name: 'review-header.document.tab.label', url: ERoutePaths.identityReview},
  // { name: 'review-header.address.tab.label', url: ERoutePaths.addressReview },
  // { name: 'review-header.a4scan.tab.label', url: ERoutePaths.a4scanReview }
];

const InfoBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode } = state.reviewResult;
  const [isMessageShow, toggleMessage] = useState(false);

  const enterPressed = (event: React.KeyboardEvent) => {
    const code = event.keyCode || event.which;
    if (code === KEY_CODE.ENTER) {
      copyCode(transactionCode, toggleMessage);
    }
  };

  const getActiveTab = (tab: TTab) => {
    return location.pathname.includes(tab.url);
  };

  const handleTransactionClose = () => navigate(ERoutePaths.dashboard);

  return (
    <div className="info-bar">
      <div className="info-bar-item">
        <p className="into_bar_text">
          {t('review-header.tc.label')}
          <span className="info_bar_tr_code">
            {' '}
            {transactionCode}
          </span>
        </p>
        <div
          className="copy-button"
          role="button"
        >
          <CopyLogo
            onKeyDown={(e) => enterPressed(e)}
            onClick={() => { copyCode(transactionCode, toggleMessage); }}
            tabIndex={5}
          />
          <div className={`${isMessageShow ? 'reg-tooltip' : 'reg-tooltip-hide'}`}>
            <span>
              {t('review-header.copied')}
              {' '}
              {transactionCode}
            </span>
          </div>
        </div>
      </div>
      <div className="tab-container">
        {!location.pathname.includes(ERoutePaths.taBoarding) ? tabs.map((tab) => (
          <button
            key={tab.name}
            className={`tab-button ${getActiveTab(tab) ? 'active' : ''}`}
            // Will be implemented in the future after adding the corresponding pages
            // onClick={() => navigate(`${tab.url}/${transactionCode}`)}
          >
            {t(tab.name)}
          </button>
        )) : null}
      </div>
      <div className="info-bar-item status">
        <div
          className="button"
          role="button"
        >
          <CloseLogo
            tabIndex={6}
            onClick={handleTransactionClose}
            onKeyPress={handleTransactionClose}
          />
        </div>
      </div>
    </div>
  );
};

export default InfoBar;

import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { getImageFromBase64 } from 'util/common';
import Loader from 'components/loader/Loader';
import { LOADING_DURATION } from 'constants/Static';
import { getNotFoundImage, getNotFoundSpecimen } from 'helper';
import { GetResourceAPI } from 'helper/api/route';

interface IComparisonProps {
  specimenImage: string;
  scale?: number;
  transactionCode: string;
  filetype: string;
  path: string;
  image?: string;
  setImageType?: (data: string) => void;
  setImages?: (data: string) => void;
}

const Comparison = (props: IComparisonProps) => {
  const { specimenImage, scale = 1, transactionCode, filetype, path, setImageType, setImages,image } = props;
  const { i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const [resourceData, setResourceData] = useState({
    documentImageLoaded: false,
    documentImageUnavailable: false,
    specimenLoaded: false,
    specimenImageNotFound: false,
    specimenImageUnavailable: false,
  });
  const [transformX, setTransformX] = useState(0);
  const [transformY, setTransformY] = useState(0);
  const [documentImage, setDocumentImage] = useState('');
  const [isOnMouseDown, setIsOnMouseDown] = useState(false);
  const [positionMouseDown, setPositionMouseDown] = useState({ x: 0, y: 0 });
  const divisorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverImageRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const coverContainerRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const firstImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;
  const secondImageRef = useRef() as React.MutableRefObject<HTMLImageElement>;

  useEffect(() => {
    divisorRef.current.style.width = `${value}%`;
  });

  useEffect(() => {
    setValue(0);
    if (!specimenImage) {
      setResourceData(prevState => ({ ...prevState, specimenImageNotFound: true }));
      return;
    }
    setResourceData(prevState => ({ ...prevState, specimenImageNotFound: false }));
    const specimenImageData = new Image();
    specimenImageData.src = specimenImage;
    specimenImageData.onload = () => {
      setResourceData(prevState => ({ ...prevState, specimenLoaded: true }));
    };
    specimenImageData.onerror = () => {
      setResourceData(prevState => ({ ...prevState, specimenImageUnavailable: true }));
    };
    const interval = setInterval(() => {
      if (!resourceData.specimenLoaded) {
        setResourceData(prevState => ({ ...prevState, specimenLoaded: true }));
      }
    }, LOADING_DURATION);
    return () => clearTimeout(interval);
  }, [specimenImage]);

  useEffect(() => {
    setValue(0);
    if (image) {
      setDocumentImage(image);
      setResourceData((prevState) => ({
        ...prevState,
        documentImageLoaded: true,
      }));
    } else if (!filetype) {
      setResourceData(prevState => ({
        ...prevState,
        documentImageLoaded: true,
        documentImageUnavailable: true,
      }));
    } else {
      GetResourceAPI(transactionCode, filetype)
        .then((response) => {
          if (response.data) {
            setDocumentImage(response.data.content);
            setResourceData(prevState => ({
              ...prevState,
              documentImageLoaded: true,
            }))
            setImages && setImages(response.data.content);
          } else {
            const interval = setInterval(() => {
              if (!resourceData.documentImageLoaded && window.location.pathname === path) window.location.reload();
              clearInterval(interval);
            }, LOADING_DURATION);
          }
        })
        .catch(() => setResourceData(prevState => ({
          ...prevState,
          documentImageLoaded: true,
          documentImageUnavailable: true,
        })));
    }
  }, [documentImage]);

  const getWidth = useCallback(() => (coverContainerRef.current.offsetWidth), [coverContainerRef]);

  const handleMouseMovement = (e: any, imageRef: any) => {
    const {
      right: parentDivRight,
      left: parentDivLeft,
      top: parentDivTop,
      bottom: parentDivBottom,
    } = coverContainerRef.current.getBoundingClientRect();
    const {
      right: imageRight,
      left: imageLeft,
      top: imageTop,
      bottom: imageBottom,
    } = imageRef.current.getBoundingClientRect();

    const movementX = e.pageX;
    const movementY = e.pageY;
    let transformY = movementY - positionMouseDown.y;
    let transformX = movementX - positionMouseDown.x;

    if (imageTop + 25 > parentDivTop) {
      if (movementY < positionMouseDown.y) {
        transformY = movementY - positionMouseDown.y;
      } else {
        transformY = 0;
      }
    }

    if (imageLeft > parentDivLeft) {
      if (movementX < positionMouseDown.x) {
        transformX = movementX - positionMouseDown.x;
      } else {
        transformX = 0;
      }
    }

    if (imageRight < parentDivRight) {
      if (movementX > positionMouseDown.x) {
        transformX = movementX - positionMouseDown.x;
      } else {
        transformX = 0;
      }
    }

    if (imageBottom - 25 < parentDivBottom) {
      if (movementY > positionMouseDown.y) {
        transformY = movementY - positionMouseDown.y;
      } else {
        transformY = 0;
      }
    }

    if (transformX !== 0) {
      setTransformX(transformX);
    }
    if (transformY !== 0) {
      setTransformY(transformY);
    }
  };

  const handleMouseUp = () => setIsOnMouseDown(false);

  const handleMouseDown = (e: any) => {
    setPositionMouseDown({ x: e.pageX, y: e.pageY });
    setIsOnMouseDown(true);
  };

  const getDocumentImage = () => {
    if (resourceData.documentImageUnavailable) {
      return getNotFoundImage(i18n.language);
    }
    return getImageFromBase64(documentImage);
  }

  const getSpecimenImage = () => {
    if (resourceData.specimenImageNotFound) {
      return getNotFoundSpecimen(i18n.language);
    }
    if (resourceData.specimenImageUnavailable) {
      return getNotFoundImage(i18n.language);
    }
    return specimenImage;
  };

  return (
    <>
      <div className="document-comparison-section" ref={coverContainerRef}>
        {(!resourceData.specimenLoaded || !resourceData.documentImageLoaded) && (
          <div className="loader-container"> <Loader /></div>
        )}
        <div className='comparison'>
          <div
            className="first-image-container"
            onMouseUp={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleMouseUp();
            }}
            onMouseDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleMouseDown(e);
            }}
            onMouseMove={(e) => {
              e.stopPropagation();
              e.preventDefault();
              isOnMouseDown && scale > 1 && handleMouseMovement(e, secondImageRef);
            }}
          >
            <button
              className='transform-image-button'
              onClick={() => {
                if (setImageType) setImageType((documentImage && filetype) || '');
              }}>
              <img
                className="image-style"
                src={getDocumentImage()}
                alt="document"
                ref={secondImageRef}
                onLoad={() => {
                  coverImageRef.current.style.width = `${getWidth()}px`;
                  setResourceData(prevState => ({
                    ...prevState,
                    documentImageLoaded: true,
                  }));
                }}
                draggable
                style={{ transform: `translate(${transformX}px,${transformY}px) scale(${scale})` }}
              />
            </button>
          </div>
          <div ref={divisorRef} className="divisor">
            <div
              className="image-cover"
              ref={coverImageRef}
              onMouseUp={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleMouseUp();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleMouseDown(e);
              }}
              onMouseMove={(e) => {
                e.stopPropagation();
                e.preventDefault();
                isOnMouseDown && scale > 1 && handleMouseMovement(e, firstImageRef);
              }}
            >
              <img
                src={getSpecimenImage()}
                alt="specimen"
                ref={firstImageRef}
                onLoad={() => {
                  coverImageRef.current.style.width = `${getWidth()}px`;
                  setResourceData(prevState => ({
                    ...prevState,
                    specimenLoaded: true,
                  }));
                }}
                style={{ transform: `translate(${transformX}px,${transformY}px) scale(${scale})` }}
              />
            </div>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(Number(e.target.value))}
        />
      </div>
    </>
  );
};

export default Comparison;
import React from 'react';
import { Modal } from 'react-bootstrap';

import Loader from 'components/loader/Loader';
import { ISingleButtonProps } from 'types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';

const BigModal = (props: any) => {
  const {
    title,
    submitButtonText,
    cancelButtonText,
    onHide,
    onBackClick,
    onSuccess,
    isDisabled,
    body,
    footerStyle,
    bodyButton,
    isModalNewAttack,
    subTitle,
    isLoading,
    show,
    className,
    isFromDocCountry,
    rejectButtonText,
    onReject,
    backdrop,
  } = props;

  const modalBackClick = () => {
    if (onBackClick) {
      onBackClick();
    } else {
      onHide();
    }
  };

  const nextButtonProps: ISingleButtonProps = {
    textButton: submitButtonText,
    isActive: !isLoading,
    isDisabled,
    onClick: () => onSuccess(),
    sizeButton: BUTTON_STYLE.OUTLINE_BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_BIG,
    className: className ? className : ''
  };

  const backButtonProps: ISingleButtonProps = {
    textButton: cancelButtonText,
    isActive: !isLoading,
    isDisabled,
    onClick: () => modalBackClick(),
    sizeButton: BUTTON_STYLE.INLINE_BIG,
    variant: BUTTON_STYLE.INLINE_BIG,
  };

  const rejectButtonProps: ISingleButtonProps = {
    textButton: rejectButtonText,
    isDisabled,
    onClick: () => onReject(),
    sizeButton: BUTTON_STYLE.OUTLINE_BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_BIG,
    className: className ? className : ''
  };

  const modalProps = {
    title,
    onHide,
    body,
    show,
    backdrop
  };

  return (
    <Modal
      {...modalProps}
      size="lg"
      className={`big-modal ${isModalNewAttack ? 'white-big-modal' : ''}`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      keyboard={false}
      onHide={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title
          className={bodyButton ? 'withButtonTitle' : ''} id="contained-modal-title-vcenter">
          {title}
          {subTitle
            && <p className="sub-title">{subTitle}</p>}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={bodyButton ? 'withButtonBody' : ''}>
        {bodyButton
          ? (
            <>
              {isFromDocCountry && <SingleButton {...rejectButtonProps} />}
              {submitButtonText ? <SingleButton {...nextButtonProps} />
                : <SingleButton {...backButtonProps} />}
              {isLoading && <Loader />}
            </>
          )
          : body}
      </Modal.Body>
      <Modal.Footer className={`${footerStyle} ${bodyButton ? 'left' : ''}`}>
        {cancelButtonText && <SingleButton {...backButtonProps} />}
        {!bodyButton
          && (
            <>
              <SingleButton {...nextButtonProps} />
              {isLoading && <Loader />}
            </>
          )}
      </Modal.Footer>
    </Modal>
  );
};

export default BigModal;

BigModal.defaultProps = {
  backdrop: true,
};

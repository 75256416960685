import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';

import useOutsideClick from 'hooks/useOutsideClick';
import { getUrlParams } from 'util/common';
import { useSelector } from 'react-redux';
import { IRootState } from 'store/types';

const SwitchLanguage = () => {
  const { t, i18n } = useTranslation();
  const [showLanguage, setShowLanguage] = useState(false);
  const languageRef = useRef(null);
  const state = useSelector((rootState: IRootState) => rootState);
  useOutsideClick(languageRef, setShowLanguage);
  const langsConfig = state.common?.appConfig.lang;
  const langs = langsConfig?.langs.filter((lang: string) => lang !== i18n.language);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setShowLanguage(false);
  };
  const translate: boolean = getUrlParams().translate === 'true';

  return (
    <div className="lang-section-button">
      { process.env.REACT_APP_ENV === 'CROWDIN' && translate ? '' :
        <Dropdown show={showLanguage} ref={languageRef} >
          <Dropdown.Toggle className="mt-2 ml-2 mr-2 mb-2">
            <div
              className="active-lang-color dropdown-items dropdown-items-text"
              onClick={ () => setShowLanguage(!showLanguage) }
              tabIndex={0}
              onKeyPress={() => setShowLanguage(!showLanguage)}
            >
              { i18n.language.toUpperCase() }
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu >
            {
              langs?.map((lang: string, index: number) => (
                <div
                  key={`lang_${index}`}
                  className="hold-lang-color dropdown-items dropdown-items-text"
                  tabIndex={0}
                  onKeyPress={() => changeLanguage(lang)}
                  onClick={ () => changeLanguage(lang)}
                >
                  {t(`header.language.label.${lang}`)}
                </div>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      }
    </div>
  );
};

export default SwitchLanguage;

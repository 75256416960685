import { MRZ_INFO_MAPPING, PERMIT } from 'constants/Static';
import { EDocumentType, EFields, EZones } from 'enums';

import {
  TDataVerification,
  TDocumentVerification,
  TDrivingCategory,
  TDrivingCategoryDetail,
  TRequiredFields,
} from 'store/features/reviewResult/types';
import { IOptionType } from 'types';

const removeZones = (allowedZones: EZones[], ...zonesToRemove: EZones[]) => {
  allowedZones = allowedZones.filter((zone) => !zonesToRemove.includes(zone));
  return allowedZones;
};

const getZones = (requiredFields: TRequiredFields, extractedData: any) => {
  let allowedZones: EZones[] = [EZones.viz];
  Object.keys(requiredFields).forEach((f) => {
    const field = EFields[f as keyof typeof EFields];
    if (requiredFields[field]) {
      const zones = Object.keys(requiredFields[field] || {}) as EZones[];
      zones.forEach((zone) => {
        if (!allowedZones.includes(zone)) {
          allowedZones.push(zone);
        }
      });
    }
  });
  if (extractedData.nfc) {
    allowedZones = removeZones(allowedZones, EZones.barcode, EZones.mrz);
  } else if (extractedData.barcode) {
    allowedZones = removeZones(allowedZones, EZones.mrz);
  }
  return allowedZones;
};

const mapCountryOptions = (options: IOptionType[], t: (label: string) => string) => {
  return options.map((option) => ({
    label: `${t(option.label)} (${option.value.toUpperCase()})`,
    value: option.value,
  }));
};

const mapOptions = (options: IOptionType[], t: (label: string) => string) => {
  return options.map((option) => ({
    label: t(option.label),
    value: option.value,
  }));
};

const getValues = (
  requiredFields: TRequiredFields,
  existingResult?: TDataVerification,
  extractedData?: TDocumentVerification
) => {
  const result: TDataVerification = {
    zones: [],
    fieldNames: [],
    fields: {},
  };
  const zones = getZones(requiredFields, extractedData);
  result.zones = zones;
  result.fields = JSON.parse(JSON.stringify(existingResult?.fields || {}));
  Object.keys(requiredFields).forEach((f) => {
    const field = f as keyof typeof EFields;
    if (field === EFields.drivingCategory) {
      result.fieldNames.push(EFields[field]);
      return;
    }
    result.fieldNames.push(EFields[field]);
    if (requiredFields[field]) {
      result.zones?.forEach((zone) => {
        let extractedValue = extractedData?.[zone]?.[field] || '';
        if (field === EFields.documentType && extractedValue === PERMIT) {
          extractedValue = EDocumentType.plasticPermit;
        }
        if (field === EFields.documentSubtype) {
          [, extractedValue] = extractedValue.split('_');
        }
        const mrzField = EFields[MRZ_INFO_MAPPING[field] as keyof typeof EFields];
        if (zone === EZones.mrz && !extractedValue && mrzField) {
          extractedValue = extractedData?.[zone]?.[mrzField] || '';
        }
        const initialData = {
          zone,
          value: extractedValue,
          extractedValue,
          disabled: [EZones.nfc, EZones.barcode].includes(zone),
          editable: !!requiredFields[field]?.[zone] || false,
          optional: requiredFields[field]?.[zone]?.optional || false,
        };
        if (!result.fields?.[field]) {
          result.fields[field] = { [zone]: initialData };
        } else if (!result.fields?.[field]?.[zone]) {
          result.fields[field] = {
            ...result.fields[field],
            [zone]: initialData,
          };
        } else {
          result.fields[field] = {
            ...result.fields[field],
            [zone]: {
              ...result.fields[field]?.[zone],
              editable: !!requiredFields[field]?.[zone] || false,
              optional: requiredFields[field]?.[zone]?.optional || false,
            },
          };
        }
      });
    }
  });
  return result;
};

const getDrivingCategoriesValues = (
  requiredFields: TRequiredFields,
  extractedData?: TDocumentVerification
) => {
  const drivingCategories: TDrivingCategory[] = [];

  Object.keys(requiredFields).forEach((f) => {
    const field = f as keyof typeof EFields;
    if (requiredFields[field]) {
      if (field === EFields.drivingCategory) {
        const { drivingCategoryDetails } = extractedData?.viz || {};
        drivingCategoryDetails?.forEach(
          (drivingCategoryDetail: TDrivingCategoryDetail) => {
            drivingCategories.push({
              value: {
                value: drivingCategoryDetail?.name || '',
                extractedValue: drivingCategoryDetail?.name || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              issuingDate: {
                value: drivingCategoryDetail?.issuingDate || '',
                extractedValue: drivingCategoryDetail?.issuingDate || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              expiryDate: {
                value: drivingCategoryDetail?.expiryDate || '',
                extractedValue: drivingCategoryDetail?.expiryDate || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
              restrictions: {
                value: drivingCategoryDetail?.restrictions || '',
                extractedValue: drivingCategoryDetail?.restrictions || '',
                zone: EZones.viz,
                optional: requiredFields[field]?.viz?.optional || false,
              },
            });
          }
        );
      }
    }
  });

  return drivingCategories;
};

const hasActiveAcceptanceCheck = (acceptanceChecks: any, acceptanceCheckNames: string[], excludedCheckName: string) => {
  const remainingCheckNames = acceptanceCheckNames.filter(
    checkName => checkName !== excludedCheckName
  );
  return remainingCheckNames.some(
    (checkName) => acceptanceChecks[checkName]
  );
};

export {
  getZones,
  removeZones,
  mapOptions,
  getValues,
  getDrivingCategoriesValues,
  mapCountryOptions,
  hasActiveAcceptanceCheck,
};

import produce from 'immer';

import { setStorageDataItemByKey } from 'util/storageHelper';
import {
  IReviewResult,
  ReviewResultActions,
  TReviewResultActions,
} from 'store/features/reviewResult/types';
import getInitialState from 'store/features/reviewResult/initialState';

export const reviewResultReducer = (
  state: IReviewResult = getInitialState(), action: TReviewResultActions,
) => {
  const nextState = produce(state, (draftState: IReviewResult) => {
    switch (action.type) {
    case ReviewResultActions.setTransactionData:
      draftState.transactionCode = action.value.transactionCode;
      draftState.transactionData = action.value.transactionData;
      setStorageDataItemByKey('transactionCode', draftState.transactionCode);
      setStorageDataItemByKey('transactionData', draftState.transactionData);
      break;
    case ReviewResultActions.setRejectionReasons:
      draftState.manualCheckResult.rejectionReasons = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setDocumentValidation:
      draftState.manualCheckResult.identityReview.documentValidationReview[0] = {
        ...draftState.manualCheckResult.identityReview.documentValidationReview[0],
        ...action.value,
      };
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setAcceptanceChecks:
      draftState.manualCheckResult.acceptanceChecks = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setA4ScanReview:
      draftState.manualCheckResult.a4ScanReview = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setAddressReview:
      draftState.manualCheckResult.addressReview = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setFaceVerificationReview:
      draftState.manualCheckResult.identityReview.faceVerificationReview = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setLivenessDetectionReview:
      draftState.manualCheckResult.identityReview.livenessDetectionReview = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setDataVerification:
      draftState.manualCheckResult.identityReview.documentValidationReview[0].dataVerification
        = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setDrivingCategory:
      draftState.manualCheckResult.identityReview.documentValidationReview[0].drivingCategory = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    case ReviewResultActions.setSpecimens:
      draftState.manualCheckResult.identityReview.documentValidationReview[0].specimens
        = action.value;
      setStorageDataItemByKey('manualCheckResult', draftState.manualCheckResult);
      break;
    }
    return draftState;
  });

  return nextState;
};

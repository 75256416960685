export enum AgentActions {
  setAgent = 'SET_AGENT',
}

export type TDataset = {
  id: number;
  name: string;
  title: string;
  selected?: boolean;
}

export type TAgentInfo = {
  id: number;
  email: string;
  name: string;
}

export type TAgent = {
  info: TAgentInfo;
  datasets: TDataset[];
}

export type TAgentActions = { type: AgentActions.setAgent; value: TAgent };


import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

import { ReactComponent as Info } from 'images/info.svg';
import MultipleSelect from 'components/select/MultipleSelect';
import ToolTip from 'components/toolTipField/ToolTipField';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import { EAnswers, EStatuses, EFileTypes, EWorkflowModules } from 'enums';
import { IRootState } from 'store/types';
import { IOptionType } from 'types';
import { LivenessRejectionReasons } from 'constants/Reasons';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import { formatRejectionReasons } from 'helper';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';

const statusMessages = {
  [EStatuses.pass]: 'identity.ld.result.label.pass',
  [EStatuses.fail]: 'identity.ld.result.label.fail',
}

const Liveness = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, transactionData, manualCheckResult } = state.reviewResult;
  const { faceLiveness } = transactionData || {};
  const { acceptanceChecks, rejectionReasons, identityReview } = manualCheckResult;
  const { livenessCheck } = faceLiveness || {};
  const result = EStatuses[livenessCheck?.result as keyof typeof EStatuses] || EStatuses.pass;
  const livenessDetectionReasons = formatRejectionReasons(
    LivenessRejectionReasons, rejectionReasons.livenessDetection, t,
  );
  const pageEnabled = transactionData?.modules.includes(EWorkflowModules.livenessDetection);
  const [videoAvailable, setVideoAvailable] = useState(true);

  const rejectionReasonsProps = {
    value: livenessDetectionReasons,
    handler: (selected: IOptionType[]) => dispatch(reviewResultActions.setRejectionReasons({
      ...rejectionReasons,
      livenessDetection: selected?.map((reason: IOptionType) => reason.value) || []
    })),
    placeholder: t('identity.ld.rejection-reason-placeholder'),
    options: LivenessRejectionReasons.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(reviewResultActions.setLivenessDetectionReview({
      result: event.target.value,
    }));
    if (event.target.value === EAnswers.yes) {
      dispatch(reviewResultActions.setRejectionReasons({
        ...rejectionReasons,
        livenessDetection: [],
      }));
    }
  }

  const selfieVideoPlayer = useMemo(() => videoAvailable ? (<VideoPlayer
    filetype={EFileTypes.selfieVideo}
    transactionCode={transactionCode}
    path={location.pathname}
    errorHandler={() => setVideoAvailable(false)}
  />) : (
    <ImageWrapper
      filetype={''}
      transactionCode={transactionCode}
      path={location.pathname}
    />
  ), [videoAvailable]);

  return (
    <div className="selfie-video-container">
      <p className="video-title">{t('identity.ld.heading')}</p>
      <div className='selfie-video-main'>
        <div className="video-section">
          {selfieVideoPlayer}
        </div>
        <div className="review-section">
          <p className="video-status">
            {t('identity.ld.result.title')}
            <span className={result}>{pageEnabled && t(statusMessages[result])}</span>
          </p>
          <div className="line"></div>
          <div className="review">
            <span className='review-text'>{t('identity.ld.guideline.heading')}</span>
            <div className='checkbox-section'>
              <input
                type="checkbox"
                disabled={!pageEnabled}
                checked={acceptanceChecks.livenessRealVideo}
                onChange={(event) => {
                  if (!event.target.checked) {
                    dispatch(batchActions([
                      reviewResultActions.setLivenessDetectionReview({
                        result: '',
                      }),
                      reviewResultActions.setRejectionReasons({
                        ...rejectionReasons,
                        livenessDetection: [],
                      }),
                    ]));
                  }
                  dispatch(reviewResultActions.setAcceptanceChecks({
                    ...acceptanceChecks,
                    livenessRealVideo: event.target.checked
                  }))
                }
                }
              />
              <p className='review-description'>
                {t('identity.ld.guideline.message1')}
                <ToolTip
                  placement='top'
                  tooltipValue={
                    <>
                      <span>{t('identity.ld.guideline.message1.tooltip')}</span>
                      <ul>
                        <li>{t('identity.ld.guideline.message2.tooltip')}</li>
                        <li>{t('identity.ld.guideline.message3.tooltip')}</li>
                        <li>{t('identity.ld.guideline.message4.tooltip')}</li>
                        <li>{t('identity.ld.guideline.message5.tooltip')}</li>
                      </ul>
                    </>
                  }
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
            <div className="options">
            </div>
          </div>
          <div className="line"></div>
          <div className="review-buttons">
            <h2 className="description">{t('review-footer.ld.question.title')}</h2>
            <div className='answer-section'>
              <Form.Check
                inline
                label={t('review-footer.answer-yes.title')}
                value={EAnswers.yes}
                name='identity.ld.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                disabled={!acceptanceChecks.livenessRealVideo}
                checked={identityReview.livenessDetectionReview.result === EAnswers.yes}
              />
              <Form.Check
                inline
                label={t('review-footer.answer-no.title')}
                value={EAnswers.no}
                name='identity.ld.answer'
                className='radio-button'
                onChange={handleChange}
                type='radio'
                disabled={!acceptanceChecks.livenessRealVideo}
                checked={identityReview.livenessDetectionReview.result === EAnswers.no}
              />
            </div>
            <div className='select-reasons'>
              <MultipleSelect disabled={
                !(acceptanceChecks.livenessRealVideo &&
                identityReview.livenessDetectionReview.result === EAnswers.no)}
              {...rejectionReasonsProps}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Liveness;

import { EReturnCodes, EReturnCodesCategories } from 'enums';
import { TReturnCodes } from 'types';

const extractReturnCodes = (returnCodes: TReturnCodes) => (
  (Object.keys(returnCodes)).reduce<EReturnCodes[]>((acc: EReturnCodes[], categoryEnum) => {
    const category = EReturnCodesCategories[categoryEnum as keyof typeof EReturnCodesCategories];
    if (returnCodes?.[category]?.length) {
      acc.push(...(returnCodes[category] || []));
    }
    return acc;
  }, [])
);

const getReturnCodeKeys = (errorCodes: TReturnCodes | undefined) => {
  if (!errorCodes) {
    return ['review-reasons.missing-error-codes'];
  }
  const result = extractReturnCodes(errorCodes).reduce((acc: string[], errorCode: string) => {
    let messageKey = '';
    switch (errorCode) {
    case EReturnCodes.EError_IdNotInList:
      messageKey = 'review-reasons.id-not-allowed';
      break;
    case EReturnCodes.EError_DocumentDataMismatch:
      messageKey = 'review-reasons.data-mismatch';
      break;
    case EReturnCodes.EError_IdMatchingFailed:
    case EReturnCodes.EError_IdNotDetected:
    case EReturnCodes.EError_IdPageMissing:
    case EReturnCodes.EError_IdMismatch:
      messageKey = 'review-reasons.unrecognized-id';
      break;
    case EReturnCodes.EError_IdFaceImageCaptureFailed:
      messageKey = 'review-reasons.face-not-extracted';
      break;
    case EReturnCodes.EError_IdNoData:
    case EReturnCodes.EError_IdIncompleteData:
      messageKey = 'review-reasons.incomplete-data-extraction';
      break;
    case EReturnCodes.EError_FaceNotRecognised:
    case EReturnCodes.EError_FaceNotVerified:
      messageKey = 'review-reasons.fv';
      break;
    case EReturnCodes.EError_FaceLivenessFailed:
      messageKey = 'review-reasons.ld';
      break;
    case EReturnCodes.EError_ImageDocumentTooFar:
    case EReturnCodes.EError_ImageBlured:
    case EReturnCodes.EError_Reflection:
      messageKey = 'review-reasons.image-quality';
      break;
    case EReturnCodes.EError_ImageInjection:
      messageKey = 'review-reasons.fraud-mounted-device-attack';
      break;
    case EReturnCodes.EError_ImageIsGreyscale:
      messageKey = 'review-reasons.fraud-greyscale-attack';
      break;
    case EReturnCodes.EError_ImageFromScreen:
      messageKey = 'review-reasons.fraud-screen-attack';
      break;
    case EReturnCodes.EError_IdBadMrzFields:
    case EReturnCodes.EError_IdBadMrzFieldBirthDay:
    case EReturnCodes.EError_IdBadMrzFieldCompositCheckDigit:
    case EReturnCodes.EError_IdBadMrzFieldExpiryDay:
    case EReturnCodes.EError_IdBadMrzFieldDocumentNumber:
    case EReturnCodes.EError_IdBadMrzFieldGender:
    case EReturnCodes.EError_IdBadMrzFieldCountry:
    case EReturnCodes.EError_IdBadMrzFieldNationality:
    case EReturnCodes.EError_MRZ_NotDetected:
      messageKey = 'review-reasons.mrz';
      break;
    case EReturnCodes.EError_NFC_TagWasLost:
    case EReturnCodes.EError_NFC_NotConnected:
    case EReturnCodes.EError_NFC_MutualAuthenticationFailedUnknown:
    case EReturnCodes.EError_NFC_MutualAuthenticationFailedNotSatisfied:
    case EReturnCodes.EError_NFC_ReadFailed:
    case EReturnCodes.EError_NFC_UnexpectedException:
    case EReturnCodes.EError_NFC_Timeout:
    case EReturnCodes.EError_NFC_NotSupported:
    case EReturnCodes.EError_NFC_CertificateValidationFailed:
    case EReturnCodes.EError_NFC_TechnicalError:
    case EReturnCodes.EError_NFC_CLONED_CHIP:
    case EReturnCodes.EError_NFC_TEMPERED_DATA:
    case EReturnCodes.EError_NFC_SOD_VS_COM_INSONSITENCY:
    case EReturnCodes.EError_NFC_INVALID_HASHES:
      messageKey = 'review-reasons.nfc';
      break;
    default:
      messageKey = 'review-reasons.default';
    }
    if (!acc.includes(messageKey)) acc.push(messageKey);
    return acc;
  }, []);
  return result;
};

export {
  extractReturnCodes,
  getReturnCodeKeys,
}
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import { useLocation } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';

import { IRootState } from 'store/types';
import { EAcceptanceCheckNames, EAnswers, EFileTypes } from 'enums';
import { IOptionType, ISelectOption, ISpecimen, TGenericItem } from 'types';
import {
  collectExtraDetailOptions,
  formatRejectionReasons,
  getCountryValue,
  getDocumentTypeValue,
  getExtraDetailValue,
} from 'helper';
import { ReactComponent as Info } from 'images/info.svg';
import { ReactComponent as SpecimenPopup } from 'images/specimen_popup.svg';
import Countries from 'constants/Countries.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import SelectOption from 'components/select/SelectOption';
import Comparison from 'components/comparison/Comparison';
import ImageWrapper from 'components/imageWrapper/ImageWrapper';
import VideoPlayer from 'components/videoPlayer/VideoPlayer';
import ToolTip from 'components/toolTipField/ToolTipField';
import MultipleSelect from 'components/select/MultipleSelect';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import {
  RecordRejectionReasons,
  DocumentRejectionReasons,
  ImageQualityRejectionReasons,
} from 'constants/Reasons';
import { GetSpecimensAPI } from 'helper/api/route';
import DataVerification from 'pages/identityReview/documentValidation/dataVerification/DataVerification';
import { DUID_LABEL_MAPPING } from 'constants/Static';
import { TSpecimen } from 'store/features/reviewResult/types';
import { hasActiveAcceptanceCheck } from 'pages/identityReview/helper';
import Modal from 'components/modal/Modal';
import Specimen from 'components/modal/Specimen';

const getDUID = (specimens: TSpecimen[], classifiedDocument: TGenericItem | undefined) => {
  const { front = '', back = '' } = classifiedDocument || {};
  if (!specimens || !specimens.length) return '';
  const detectedSpecimen = specimens.find(({ duid }) =>
    (front && duid.startsWith(front)) || (back && duid.startsWith(back))
  );
  return detectedSpecimen?.duid || specimens[0]?.duid || '';
}

const filteredSpecimens = (
  specimens: TSpecimen[], value: string
) => specimens.filter(({ extraDetail }) => extraDetail === value);

const DocumentValidation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionCode, manualCheckResult } = state.reviewResult;
  const { acceptanceChecks, rejectionReasons, identityReview } = manualCheckResult;
  const { documentValidationReview = [] } = identityReview;
  const [{
    result,
    classifiedDocument,
    specimenNotFound = false,
  }] = documentValidationReview;
  const [modalShow, setModalShow] = useState(false);
  const [specimens, setSpecimens] = useState<ISpecimen[]>(documentValidationReview[0].specimens || []);
  const [documentType, setDocumentType] = useState<string>(documentValidationReview[0].documentType || '');
  const [documentCountry, setDocumentCountry] = useState<string>(documentValidationReview[0].documentCountry || '');
  const [extraDetails, setExtraDetails] = useState<IOptionType[]>(documentValidationReview[0].extraDetails || []);
  const [duid, setDuid] = useState<string>(documentValidationReview[0].duid || '');
  const [extraDetail, setExtraDetail] = useState<string>(documentValidationReview[0].extraDetail || '');
  const [imageType, setImageType] = useState('');
  const [images, setImages] = useState({
    fullFrameFirstPage: '',
    fullFrameSecondPage: '',
    signatureImage: '',
    firstImage: '',
    secondImage: '',
  });
  const currentSpecimen = specimens.find((specimen) => specimen?.duid === duid);
  const [videoData, setVideoData] = useState<{ [key in EFileTypes]?: boolean }>({
    [EFileTypes.firstVideo]: true,
    [EFileTypes.secondVideo]: true,
  });

  const imageQualityReasons = useMemo(() => formatRejectionReasons(
    ImageQualityRejectionReasons, rejectionReasons.imageQuality, t,
  ), [rejectionReasons]);

  const documentReasons = useMemo(() => formatRejectionReasons(
    DocumentRejectionReasons, rejectionReasons.document, t,
  ), [rejectionReasons]);

  const documentRecordingReasons = useMemo(() => formatRejectionReasons(
    RecordRejectionReasons, rejectionReasons.documentRecording, t,
  ), [rejectionReasons]);

  useEffect (() => {
    setDuid(documentValidationReview[0].duid || '');
  } , [documentValidationReview[0].duid]);

  useEffect(() => {
    dispatch(reviewResultActions.setDocumentValidation({
      documentType,
      documentCountry,
      extraDetail,
      extraDetails,
      duid,
      specimens,
    }));
  }, [documentType, documentCountry, extraDetail, extraDetails, duid, specimens]);

  useEffect(() => {
    if (documentType && documentCountry) {
      GetSpecimensAPI(documentType, documentCountry.toLowerCase()).then((response) => {
        const extraDetails = collectExtraDetailOptions(response.data, t);
        const hasDetectedExtraDetail = extraDetails.some(({ value }) => value === extraDetail);
        if (extraDetails.length && !hasDetectedExtraDetail) {
          setDuid('');
          setExtraDetail('');
        } else if (!extraDetails.length) {
          const duid = getDUID(response.data, classifiedDocument);
          setDuid(duid);
          setExtraDetail('');
        } else if (extraDetail) {
          const duid = getDUID(response.data, classifiedDocument);
          setDuid(duid);
        }
        setExtraDetails(extraDetails);
        setSpecimens(response.data);
      }).catch(() => {
        setDuid('');
        setExtraDetail('');
        setExtraDetails([]);
        setSpecimens([]);
        dispatch(reviewResultActions.setDocumentValidation({
          specimenNotFound: true,
        }));
      });
    }
  }, [documentType, documentCountry]);

  const countryProps: ISelectOption = useMemo(() => {
    return {
      value: getCountryValue(documentCountry, t),
      handler: (selectedOption: IOptionType) => {
        setDocumentCountry(selectedOption.value);
      },
      uid: uuid(),
      placeholder: t('identity.doc.country.label'),
      options: Countries.map((option) => ({
        label: `${t(option.label)} (${option.value.toUpperCase()})`,
        value: option.value,
      })),
      styledScrollBar: 'select-scroll-bar',
    };
  }, [documentCountry]);

  const documentTypeProps: ISelectOption = useMemo(() => {
    return {
      value: getDocumentTypeValue(documentType, t),
      handler: (selectedOption: IOptionType) => {
        setDocumentType(selectedOption.value);
      },
      uid: uuid(),
      placeholder: t('identity.doc.type.label'),
      options: DocumentTypes.map((docType: IOptionType) => ({
        label: t(docType.label),
        value: docType.value,
      })),
      styledScrollBar: 'select-scroll-bar',
    };
  }, [documentType]);

  const extraDetailProps: ISelectOption = useMemo(() => {
    return {
      value: getExtraDetailValue(extraDetail, extraDetails),
      handler: (selectedOption: IOptionType) => {
        const extraDetail = selectedOption.value;
        const duid = getDUID(filteredSpecimens(specimens, extraDetail), classifiedDocument);
        setDuid(duid);
        setExtraDetail(extraDetail);
      },
      uid: uuid(),
      placeholder: t('identity.doc.subtype.label'),
      options: extraDetails || [],
      styledScrollBar: 'select-scroll-bar',
    }
  }, [specimens, extraDetail, extraDetails]);

  const reasonsMap = {
    imageQuality: imageQualityReasons,
    document: documentReasons,
    documentRecording: documentRecordingReasons,
  };

  const createRejectionProps = (
    key: keyof typeof reasonsMap,
    reasonOptions: IOptionType[]
  ) => ({
    value: reasonsMap[key],
    handler: (selected: IOptionType[]) => dispatch(
      reviewResultActions.setRejectionReasons({
        ...rejectionReasons,
        [key]: selected?.map((reason: IOptionType) => reason.value) || [],
      })
    ),
    uid: uuid(),
    placeholder: t('identity.doc.rejection-reason-placeholder'),
    options: reasonOptions.map((reason: IOptionType) => ({
      label: t(reason.label),
      value: reason.value,
    })),
  });

  const imageQualityRejectionReasonsProps = createRejectionProps(
    'imageQuality',
    ImageQualityRejectionReasons
  );
  const documentRejectionReasonsProps = createRejectionProps(
    'document',
    DocumentRejectionReasons
  );
  const recordRejectionReasonsProps = createRejectionProps(
    'documentRecording',
    RecordRejectionReasons
  );

  const dvRejectionReasons = useMemo(
    () => [
      {
        label: t('review-footer.doc.rejection-quality.label'),
        props: imageQualityRejectionReasonsProps,
      },
      {
        label: t('review-footer.doc.rejection-document.label'),
        props: documentRejectionReasonsProps,
      },
      {
        label: t('review-footer.doc.rejection-recording.label'),
        props: recordRejectionReasonsProps,
      },
    ],
    [imageQualityRejectionReasonsProps, documentRejectionReasonsProps, recordRejectionReasonsProps]
  )

  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      reviewResultActions.setDocumentValidation({
        result: event.target.value,
      })
    );
    if (event.target.value === EAnswers.yes) {
      dispatch(
        reviewResultActions.setRejectionReasons({
          ...rejectionReasons,
          imageQuality: [],
          document: [],
          documentRecording: [],
        })
      );
    }
  };

  const acceptances = [
    {
      flag: acceptanceChecks.documentValid,
      name: EAcceptanceCheckNames.documentValid,
      translationKeys: {
        heading: 'identity.doc.guideline.message1.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message1.1.tooltip',
          'identity.doc.guideline.sub-message1.2.tooltip',
          'identity.doc.guideline.sub-message1.3.tooltip',
          'identity.doc.guideline.sub-message1.4.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentPresented,
      name: EAcceptanceCheckNames.documentPresented,
      translationKeys: {
        heading: 'identity.doc.guideline.message2.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message2.1.tooltip',
          'identity.doc.guideline.sub-message2.2.tooltip',
          'identity.doc.guideline.sub-message2.3.tooltip',
          'identity.doc.guideline.sub-message2.4.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentPhysicalSigns,
      name: EAcceptanceCheckNames.documentPhysicalSigns,
      translationKeys: {
        heading: 'identity.doc.guideline.message3.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message3.1.tooltip',
          'identity.doc.guideline.sub-message3.2.tooltip',
          'identity.doc.guideline.sub-message3.3.tooltip',
          'identity.doc.guideline.sub-message3.4.tooltip',
          'identity.doc.guideline.sub-message3.5.tooltip',
          'identity.doc.guideline.sub-message3.6.tooltip',
        ]
      },
    },
    {
      flag: acceptanceChecks.documentClearlyVisible,
      name: EAcceptanceCheckNames.documentClearlyVisible,
      translationKeys: {
        heading: 'identity.doc.guideline.message4.tooltip',
        listItems: [
          'identity.doc.guideline.sub-message4.1.tooltip',
          'identity.doc.guideline.sub-message4.2.tooltip',
          'identity.doc.guideline.sub-message4.3.tooltip',
          'identity.doc.guideline.sub-message4.4.tooltip',
          'identity.doc.guideline.sub-message4.5.tooltip',
          'identity.doc.guideline.sub-message4.6.tooltip',
          'identity.doc.guideline.sub-message4.7.tooltip',
        ]
      },
    },
  ];

  const fullFrameFirstPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameFirstPage}
    path={location.pathname}
    setImageType={(imageType) => setImageType(imageType)}
    setImages={(data) => setImages(prevState => ({ ...prevState, fullFrameFirstPage: data }))}
  />, []);

  const fullFrameSecondPageImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.fullFrameSecondPage}
    path={location.pathname}
    setImageType={(imageType) => setImageType(imageType)}
    setImages={(data) => setImages(prevState => ({ ...prevState, fullFrameSecondPage: data }))}
  />, []);

  const signatureImageWrapper = useMemo(() => <ImageWrapper
    transactionCode={transactionCode}
    filetype={EFileTypes.signatureImage}
    path={location.pathname}
    setImageType={(imageType) => setImageType(imageType)}
    setImages={(data) => setImages(prevState => ({ ...prevState, signatureImage: data }))}
  />, []);

  const firstImageComparison = useMemo(() => <Comparison
    specimenImage={!specimenNotFound ? currentSpecimen?.front || '' : ''}
    transactionCode={transactionCode}
    filetype={EFileTypes.firstImage}
    path={location.pathname}
    setImageType={(imageType) => setImageType(imageType)}
    setImages={(data) => setImages(prevState => ({ ...prevState, firstImage: data }))}
  />, [duid, currentSpecimen, specimenNotFound]);

  const secondImageComparison = useMemo(() => <Comparison
    specimenImage={!specimenNotFound ? currentSpecimen?.back || '' : ''}
    transactionCode={transactionCode}
    filetype={EFileTypes.secondImage}
    path={location.pathname}
    setImageType={(imageType) => setImageType(imageType)}
    setImages={(data) => setImages(prevState => ({ ...prevState, secondImage: data }))}
  />, [duid, currentSpecimen, specimenNotFound]);

  const firstVideoPlayer = useMemo(() => videoData.firstVideo ? (<VideoPlayer
    filetype={EFileTypes.firstVideo}
    transactionCode={transactionCode}
    path={location.pathname}
    errorHandler={() => setVideoData(prevState => ({ ...prevState, [EFileTypes.firstVideo]: false, }))}
  />) : (
    <ImageWrapper
      filetype={''}
      transactionCode={transactionCode}
      path={location.pathname}
    />
  ), [videoData]);

  const secondVideoPlayer = useMemo(() => videoData.secondVideo ? (<VideoPlayer
    filetype={EFileTypes.secondVideo}
    transactionCode={transactionCode}
    path={location.pathname}
    errorHandler={() => setVideoData(prevState => ({ ...prevState, [EFileTypes.secondVideo]: false, }))}
  />) : (
    <ImageWrapper
      filetype={''}
      transactionCode={transactionCode}
      path={location.pathname}
    />
  ), [videoData]);

  return (
    <>
      <div className='document-validation-section'>
        <h1>{t('identity.doc.heading')}</h1>
        <div className='clarifier-selection-section'>
          <div>
            <p>{t('identity.doc.country.label')}</p>
            <SelectOption {...countryProps} />
          </div>
          <div>
            <p>{t('identity.doc.type.label')}</p>
            <SelectOption {...documentTypeProps} />
          </div>
          {!!extraDetails.length && (
            <div>
              <p>{t('identity.doc.subtype.label')}</p>
              <SelectOption {...extraDetailProps} />
            </div>
          )}
        </div>
        <div className='document-validation'>
          <div className='document-validation-image-section'>
            <div className='document-validation-image-container'>
              <h2>{t('identity.doc.first-page.title')}</h2>
              <div className='image-wrapper-container'>
                {fullFrameFirstPageImageWrapper}
              </div>
              <div className='doc-validation-modal-button'>
                <SpecimenPopup
                  className={`${!duid || !images.firstImage ? 'is-disabled' : ''}`}
                  onClick={() => duid && images.firstImage && setModalShow(true)}
                />
              </div>
              <div className='document-validation-image-comparison'>
                {firstImageComparison}
              </div>
              <div className='video-player-section'>
                {firstVideoPlayer}
              </div>
            </div>
            <div className='document-validation-image-container'>
              <h2>{t('identity.doc.second-page.title')}</h2>
              <div className='image-wrapper-container'>
                {fullFrameSecondPageImageWrapper}
              </div>
              <div className='doc-validation-modal-button'>
                <SpecimenPopup
                  className={`${!duid || !images.secondImage ? 'is-disabled' : ''}`}
                  onClick={() => duid && images.secondImage && setModalShow(true)}
                />
                {modalShow && (
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    heading='specimen.heading'
                  >
                    <Specimen
                      firstImage={images.firstImage}
                      secondImage={images.secondImage}
                      onHide={() => setModalShow(false)}
                      specimens={specimens}
                    />
                  </Modal>
                )}
              </div>
              <div className='document-validation-image-comparison'>
                {secondImageComparison}
              </div>
              <div className='video-player-section'>
                {secondVideoPlayer}
              </div>
            </div>
          </div>
          {!!imageType && <Modal
            show={!!imageType}
            onHide={() => setImageType('')}
          >
            <div className='face-image-wrapper-container'>
              <ImageWrapper
                transactionCode={transactionCode}
                image={images[imageType as keyof typeof images] || ''}
                enableZoom={true}
              />
            </div>
          </Modal>}
          <div className='document-validation-verification-section'>
            <h2>{t('identity.doc.signature.title')}</h2>
            <div className='document-validation-verification-image-container'>
              <div className='image-wrapper-container'>
                {signatureImageWrapper}
              </div>
            </div>
            <DataVerification
              documentType={documentType}
              documentCountry={documentCountry}
              extraLabel={DUID_LABEL_MAPPING[duid] || ''}
            />
          </div>
        </div>
        <div className='review-section-container'>
          <div className='line'></div>
          <h2>{t('identity.doc.guideline.heading')}</h2>
          {acceptances.map((item, index) => (
            <div className='checkbox-section' key={index}>
              <input
                type='checkbox'
                checked={item.flag}
                onChange={(event) => {
                  if (!event.target.checked &&
                    !hasActiveAcceptanceCheck(acceptanceChecks,
                      [
                        EAcceptanceCheckNames.documentPresented,
                        EAcceptanceCheckNames.documentValid,
                        EAcceptanceCheckNames.documentPhysicalSigns,
                        EAcceptanceCheckNames.documentClearlyVisible
                      ],
                      item.name)
                  ) {
                    dispatch(batchActions([
                      reviewResultActions.setDocumentValidation({
                        ...documentValidationReview[0],
                        result: '',
                      }),
                      reviewResultActions.setRejectionReasons({
                        ...rejectionReasons,
                        imageQuality: [],
                        document: [],
                        documentRecording: [],
                      }),
                    ]));
                  }
                  dispatch(
                    reviewResultActions.setAcceptanceChecks({
                      ...acceptanceChecks,
                      [item.name]: event.target.checked,
                    })
                  )
                }
                }
              />
              <p className='review-description'>
                {t(`identity.doc.guideline.message${index + 1}`)}
                <ToolTip
                  placement='top'
                  tooltipValue={
                    <>
                      <span>{t(item.translationKeys.heading)}</span>
                      <ul>
                        {
                          item.translationKeys.listItems.map((key: string, index: number) => (
                            <li key={index}>{t(key)}</li>
                          ))
                        }
                      </ul>
                    </>
                  }
                >
                  <Info />
                </ToolTip>
              </p>
            </div>
          ))}
          <div className='line'></div>
          <div className='review-buttons'>
            <h2 className='description'>
              {t('review-footer.doc.question.title')}
            </h2>
            <div className='answer-section'>
              <Form.Check
                inline
                label={t('review-footer.answer-yes.title')}
                value={EAnswers.yes}
                name='identity.doc.answer'
                className='radio-button'
                onChange={handleAnswerChange}
                type='radio'
                disabled={!acceptances.some((item) => item.flag)}
                checked={result === EAnswers.yes}
              />
              <Form.Check
                inline
                label={t('review-footer.answer-no.title')}
                value={EAnswers.no}
                name='identity.doc.answer'
                className='radio-button'
                onChange={handleAnswerChange}
                type='radio'
                disabled={!acceptances.some((item) => item.flag)}
                checked={result === EAnswers.no}
              />
            </div>
            <div className='select-reasons'>
              <h2>{t('identity.doc.rejection-reason.title')}</h2>
              <div className='multi-select-container'>
                {dvRejectionReasons.map((reason, index) => (
                  <div className='multi-select-item' key={index}>
                    <p>{reason.label}</p>
                    <MultipleSelect
                      disabled={!(acceptances.some((item) => item.flag) && result === EAnswers.no)}
                      {...reason.props}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentValidation;

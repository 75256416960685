import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import InfoBar from 'pages/review/infoBar/InfoBar';
import Footer from 'pages/review/footer/Footer';
import IdentityReview from 'pages/identityReview/IdentityReview';
import { getReturnCodeKeys } from 'helper/extractedDataHandling';
import { IRootState } from 'store/types';
import { ERoutePaths } from 'enums';
import BurgerMenu from 'components/burgerMenu/BurgerMenu';
import BigModal from 'components/bigModal/BigModal';

const getActivePage = (pathname: string) => {
  if (pathname.includes(ERoutePaths.identityReview)) {
    return <IdentityReview />;
  }
  return null;
};

const Review = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const [showInstructions, setShowInstructions] = React.useState(false);
  const [show, setShow] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [responseError, setResponseError] = useState<boolean>(false);

  return (<>
    <InfoBar />
    <BurgerMenu showInstructions={showInstructions} />
    <div className='manual-check-reasons-section'>
      <p>{t('review-header.reasons.label')}
        <span>{getReturnCodeKeys(transactionData?.returnCodes)?.map(
          (key: string) => t(key)).join(', ')
        }</span>
      </p>
      <p
        className='need-some-help'
        onClick={() => setShowInstructions(!showInstructions)}
      >
        {t('review-header.lnk.help')}
      </p>
    </div>
    <div className='review-content'>
      {getActivePage(location.pathname)}
      {show && <BigModal
        title={!responseError && t('accept-modal.title')}
        submitButtonText="accept-modal.btn"
        footerStyle="modal-footer-join"
        setShow={setShow}
        onSuccess={() => setShow(false)}
        show={show}
        onHide={() => setShow(false)}
        body={errorMessages.length ?
          errorMessages.map((message: string, index: number) => <div key={index}>{message}</div>) : null
        }
      />}
      <Footer
        setShow={setShow}
        handleAnswerChange={(errorMessages: string[]) => setErrorMessages(errorMessages)}
        setResponseError={setResponseError}
      />
    </div>
  </>);
};

export default Review;

import { getStorageData } from 'util/storageHelper';
import { ICommon } from 'store/features/common/types';

export default (): ICommon => {
  const data: string | null = getStorageData('state');
  const configData: string | null = getStorageData('appConfig');
  const defaultTransactionCode = getStorageData('defaultTransactionCode');
  const storageData = data ? JSON.parse(data) : {};
  const appConfigData = configData ? JSON.parse(configData) : {};
  return {
    docRequiredFields: storageData.docRequiredFields || {},
    defaultTransactionCode: defaultTransactionCode || '',
    dashboard: {
      items: [],
      page: 1,
      size: 0,
      totalItems: 0,
      active: true,
    },
    loading: false,
    appConfig: appConfigData || {},
  };
};

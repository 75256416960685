import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ISingleButtonProps } from 'types';
import { IRootState } from 'store/types';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE } from 'constants/Static';
import { ReactComponent as BackButton } from 'images/right.svg';
import { ERoutePaths } from 'enums';
import { UnlockTransactionAPI } from 'helper/api/route';
import { getDocType, translateDocTypeCountry } from 'helper';
import InfoBar from 'pages/review/infoBar/InfoBar';
import { getReturnCodeKeys } from 'helper/extractedDataHandling';

const TABoarding = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData } = state.reviewResult;
  const { transactionCode, manualCheckResult } = state.reviewResult;
  const { documentValidationReview = [] } = manualCheckResult.identityReview;
  const { modules = [] } = transactionData || {};
  const [{ documentType = '', documentCountry = '' }] = documentValidationReview;
  const name = state.agent?.info?.name || '';

  const formattedModules = useMemo(() => (
    modules.map(module => module.replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/\b\w/g, char => char.toUpperCase())
    ).join(' - ')
  ), [modules]);

  const BigButtonProps: ISingleButtonProps = {
    textButton: 'boarding.review.btn',
    onClick: () => navigate(`${ERoutePaths.identityReview}/${transactionCode}`),
    isActive: true,
    sizeButton: BUTTON_STYLE.BIG,
    radiusButton: BUTTON_STYLE.CIRCLE_BUTTON,
    variant: BUTTON_STYLE.OUTLINE_SECONDARY,
  };

  const back = () => {
    if (transactionCode) UnlockTransactionAPI(transactionCode);
    navigate(ERoutePaths.dashboard);
  };

  return (
    <>
      <InfoBar />
      <div className="ta-boarding">
        <div className="ta-boarding-info">
          <p className="ta-boarding-title">
            {t('boarding.hi')}
            {' '}
            {name}
            {' '}
            {t('boarding.please.review')}
            {' '}
            { transactionCode }
          </p>
          <>
            <p className="ta-failed-text">{t('boarding.reasons.message')}</p>
            <ul className="ta-failed-reason">
              {getReturnCodeKeys(transactionData?.returnCodes).map((fail) => (
                <li key={`TAFailedReason_${fail}`}>{t(fail)}</li>))}
            </ul>
          </>
          <p>{t('boarding.doc.message')}</p>
          <ul className="ta-swiss">
            {[getDocType(documentType, documentCountry)]
              .map((docType: string[], index: number) => (
                <li key={`${`TADocType${docType[0]}`}-${index.toString()}`}>
                  {translateDocTypeCountry(docType, t)}
                </li>
              ))
            }
          </ul>
          <p>{t('boarding.checks.modules.message')}</p>
          <p className="ta-check-type">
            {formattedModules}
          </p>
        </div>
        <div className="ta-boarding-buttons-container">
          <div className="ta-boarding-back-button-container">
            <SingleButton
              textButton={'boarding.btn.back'}
              onClick={back}
              variant={BUTTON_STYLE.OUTLINE_PRIMARY}
              radiusButton={BUTTON_STYLE.CIRCLE_BUTTON}
              LeftIcon={BackButton}
            />
          </div>
          <div className="start-transaction-button-ta-boarding">
            <SingleButton {...BigButtonProps} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TABoarding;

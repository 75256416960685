import React, { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { BUTTON_TYPE, KEY_CODE } from 'constants/Static';
import { IAnswerOption } from 'types';

interface IGroupButtonsProps {
  listButtons?: Array<any>;
  onClick: any;
  variant?: string | 'primary';
  radiusButton?: string | undefined;
  type?: any | 'radio' | 'checkbox';
  activeValues?: Array<any>;
  name?: string;
  answers?: IAnswerOption[];
  isDisabled?: boolean | false;
  isActive?: boolean | false;
}

const GroupButtons = (state: IGroupButtonsProps) => {
  const {
    listButtons,
    onClick,
    variant,
    radiusButton,
    type,
    activeValues,
  } = state;

  const { t } = useTranslation();
  const [checkBoxValues, setCheckBoxValues] = useState([]) as any;
  const [buttonsState, setButtonsState] = useState() as any;

  const setSelectedRadioButtonValue = (e: any) => {
    const copyState = checkBoxValues;
    const checkedIndex = checkBoxValues.indexOf(e.target.value);
    if (checkedIndex > -1) {
      copyState.splice(checkedIndex, 1);
      setCheckBoxValues(copyState);
    } else if (checkedIndex === -1) {
      copyState.push(e.target.value);
      setCheckBoxValues(copyState);
    }
  };

  const handlerCheckboxData = (data: Array<any>) => data?.map((item) => JSON.parse(item));

  const handlerActiveIndexes = (index: number) => {
    if (activeValues?.length) {
      return activeValues?.every(elem => elem === index);
    }
    return false;
  };

  if (!listButtons?.length) return null;

  const toggleButtonHandler = (
    event: any,
    groupButtonItems: any,
    targetValue: number,
    buttonType: any,
  ) => {
    targetValue && (!buttonType || buttonType === BUTTON_TYPE.CHECKBOX) ?
      setSelectedRadioButtonValue(event) :
      setButtonsState(groupButtonItems);
  };

  const groupButtonHandler =(event: any,
    buttonsStateData: any,
    checkValues: any,
    targetValue: number,
    buttonType: any,
  ) => {
    event.stopPropagation();
    buttonType === BUTTON_TYPE.RADIO || !buttonType ?
      targetValue && onClick(buttonsStateData) : onClick(handlerCheckboxData(checkValues));
  };

  return (
    <div className={`custom-group-buttons-container ${radiusButton}`}>
      <ButtonGroup
        className={`${variant}`}
        onClick={
          (e: any) => groupButtonHandler(e, buttonsState, checkBoxValues, e.target?.value, type)}
        defaultValue={activeValues}
        onKeyUp={(e: any) => {
          const code = e.keyCode || e.which;
          code === KEY_CODE.ENTER
            && groupButtonHandler(e, buttonsState, checkBoxValues,
              e.target?.childNodes?.[0]?.value, type);
        }}
      >
        {
          listButtons?.map((groupButtonItems, index: number) => {
            return (
              <ToggleButton
                type={type ? type : BUTTON_TYPE.RADIO}
                value={index}
                key={`${groupButtonItems.text}-${index}`}
                id={`${index}`}
                onClick={
                  (e: any) => toggleButtonHandler(e, groupButtonItems, e.target?.value, type)}
                checked={
                  ((type === BUTTON_TYPE.RADIO && activeValues) || !type)
                    && handlerActiveIndexes(index)}
                className={groupButtonItems.radius}
                tabIndex={0}
                onKeyUp={(e: any) => {
                  if (e.shiftKey && (e.keyCode === KEY_CODE.TAB || e.keyCode === KEY_CODE.TAB)) {
                    toggleButtonHandler(
                      e,
                      groupButtonItems,
                      e.target?.childNodes?.[0]?.value,
                      type,
                    );
                  }
                }}
              >
                {groupButtonItems.isTranslated ? groupButtonItems.text : t(groupButtonItems.text)}
              </ToggleButton>
            );
          })
        }
      </ButtonGroup>
    </div>
  );
};

export default GroupButtons;

import React from 'react';
import { Navigate } from 'react-router-dom';

import { isAuthorized } from 'util/authService';
import { ERoutePaths } from 'enums';

const PublicRoute = (props: { component: React.ReactElement }) => {
  if (isAuthorized()) {
    return <Navigate to={ERoutePaths.root}/>;
  }

  return props.component;
};

export default PublicRoute;

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';

import FieldComparisonInput from 'components/fieldComparison/FieldComparison';
import FieldComparisonSelector from 'components/fieldComparison/FieldComparisonSelector';
import FieldComparisonDatePicker from 'components/fieldComparison/FieldComparisonDatePicker';
import Nationalities from 'constants/Nationalities.json';
import DocumentTypes from 'constants/DocumentTypes.json';
import GenderOptions from 'constants/GenderOptions.json';
import Countries from 'constants/Countries.json';
import { collectExtraDetailOptions } from 'helper';
import { getDrivingCategoriesValues, getValues, mapCountryOptions, mapOptions } from 'pages/identityReview/helper';
import { EFields } from 'enums';
import { IRootState } from 'store/types';
import { GetRequiredFieldsAPI } from 'helper/api/route';
import { TFields, TFieldData } from 'store/features/reviewResult/types';
import * as reviewResultActions from 'store/features/reviewResult/actions';
import DrivingField from 'pages/identityReview/documentValidation/dataVerification/drivingField/DrivingField';

interface IPersonalInfoProps {
  documentType: string;
  documentCountry: string;
  extraLabel?: string;
}

const DataVerification = (props: IPersonalInfoProps) => {
  const { documentCountry, documentType, extraLabel } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((rootState: IRootState) => rootState);
  const { transactionData, transactionCode, manualCheckResult } = state.reviewResult;
  const { identityReview } = manualCheckResult || {};
  const { documentValidationReview } = identityReview || {};
  const [{ dataVerification, specimens = [], duid, specimenNotFound }] = documentValidationReview || [];
  const { documentVerification } = transactionData || {};
  const { zones, fields = {}, fieldNames = [] } = dataVerification || {};

  useEffect(() => {
    if (documentCountry && documentType && (duid || specimenNotFound)) {
      GetRequiredFieldsAPI(transactionCode, {
        documentCountry: documentCountry.toLowerCase(), documentType, extraLabel,
      }).then((response) => {
        const dataVerificationValues = getValues(
          response.data,
          dataVerification,
          documentVerification?.[0]
        );
        if (!documentValidationReview[0].drivingCategory) {
          const drivingCategory = getDrivingCategoriesValues(response.data, documentVerification?.[0]);
          dispatch(reviewResultActions.setDrivingCategory(drivingCategory));
        }
        dispatch(reviewResultActions.setDocumentValidation({
          dataVerification: dataVerificationValues,
        }));
      }).catch(() => {
        dispatch(reviewResultActions.setDocumentValidation({
          dataVerification: {
            fields: dataVerification?.fields ?? {},
            zones: [],
            fieldNames: []
          },
        }));
      });
    } else {
      dispatch(reviewResultActions.setDocumentValidation({
        dataVerification: {
          fields: dataVerification?.fields ?? {},
          zones: [],
          fieldNames: []
        },
      }));
    }
  }, [duid, specimenNotFound, documentCountry, documentType, extraLabel]);

  const countryOptions = useMemo(() => mapCountryOptions(Countries, t), [t]);
  const nationalityOptions = useMemo(() => mapCountryOptions(Countries.concat(Nationalities), t), [t]);
  const documentTypeOptions = useMemo(() => mapOptions(DocumentTypes, t), [t]);
  const genderOptions = useMemo(() => mapOptions(GenderOptions, t), [t]);
  const docSubTypeOptions = collectExtraDetailOptions(specimens, t);

  const handleFieldChange = (
    fieldKey: keyof TFields,
    updatedFieldData: TFieldData
  ) => {
    const updatedDataVerification = {
      fields: {
        ...dataVerification?.fields,
        [fieldKey]: {
          ...dataVerification?.fields[fieldKey],
          ...updatedFieldData,
        },
      },
      zones: dataVerification?.zones ?? [],
      fieldNames: dataVerification?.fieldNames ?? [],
    };
    dispatch(
      reviewResultActions.setDocumentValidation({
        dataVerification: updatedDataVerification,
      })
    );
  };

  return (
    <div className='data-verification'>
      {!dataVerification || !zones || !zones.length ? (
        <h6 className='no-data-verification'>{t('identity.doc.data.no-doc-error.message')}</h6>
      ) : (
        <>
          <h2>{t('identity.doc.data.title')}</h2>
          <div className='verification-table'>
            <div className={`verification-filed-row ${zones.length === 1 ? 'single': ''}`}>
              {zones.map((zone: string) => (
                <span key={uuid()}>{zone.toUpperCase()}</span>
              ))}
            </div>
            <div className='verification-row'>
              {fieldNames.includes(EFields.dateOfBirth) && (
                <FieldComparisonDatePicker
                  zones={zones}
                  fieldKey={EFields.dateOfBirth}
                  fieldData={fields.dateOfBirth}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.dateOfBirth,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.documentNumber) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.documentNumber}
                  fieldData={fields.documentNumber}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.documentNumber,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.firstName) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.firstName}
                  fieldData={fields.firstName}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.firstName, updatedFieldData)
                  }
                />
              )}
              {fieldNames.includes(EFields.lastName) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.lastName}
                  fieldData={fields.lastName}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.lastName, updatedFieldData)
                  }
                />
              )}
              {fieldNames.includes(EFields.gender) && (
                <FieldComparisonSelector
                  zones={zones}
                  fieldKey={EFields.gender}
                  fieldData={fields.gender}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.gender, updatedFieldData)
                  }
                  options={genderOptions}
                />
              )}
              {fieldNames.includes(EFields.nationality) && (
                <FieldComparisonSelector
                  zones={zones}
                  fieldKey={EFields.nationality}
                  fieldData={fields.nationality}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.nationality,
                      updatedFieldData
                    )
                  }
                  options={nationalityOptions}
                />
              )}
              {fieldNames.includes(EFields.documentCountry) && (
                <FieldComparisonSelector
                  zones={zones}
                  fieldKey={EFields.documentCountry}
                  fieldData={fields.documentCountry}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.documentCountry,
                      updatedFieldData
                    )
                  }
                  options={countryOptions}
                />
              )}
              {fieldNames.includes(EFields.documentType) && (
                <FieldComparisonSelector
                  zones={zones}
                  fieldKey={EFields.documentType}
                  fieldData={fields.documentType}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.documentType,
                      updatedFieldData
                    )
                  }
                  options={documentTypeOptions}
                />
              )}
              {fieldNames.includes(EFields.authority) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.authority}
                  fieldData={fields.authority}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.authority, updatedFieldData)
                  }
                />
              )}
              {fieldNames.includes(EFields.placeOfBirth) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.placeOfBirth}
                  fieldData={fields.placeOfBirth}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.placeOfBirth,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.expirationDate) && (
                <FieldComparisonDatePicker
                  zones={zones}
                  fieldKey={EFields.expirationDate}
                  fieldData={fields.expirationDate}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.expirationDate,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.dateOfIssue) && (
                <FieldComparisonDatePicker
                  zones={zones}
                  fieldKey={EFields.dateOfIssue}
                  fieldData={fields.dateOfIssue}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.dateOfIssue,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.address) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.address}
                  fieldData={fields.address}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.address, updatedFieldData)
                  }
                />
              )}
              {fieldNames.includes(EFields.endorsements) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.endorsements}
                  fieldData={fields.endorsements}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.endorsements,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.restrictions) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.restrictions}
                  fieldData={fields.restrictions}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.restrictions,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.fullName) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.fullName}
                  fieldData={fields.fullName}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(EFields.fullName, updatedFieldData)
                  }
                />
              )}
              {fieldNames.includes(EFields.documentSubtype) && (
                <FieldComparisonSelector
                  zones={zones}
                  fieldKey={EFields.documentSubtype}
                  fieldData={fields.documentSubtype}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.documentSubtype,
                      updatedFieldData
                    )
                  }
                  options={docSubTypeOptions}
                />
              )}
              {fieldNames.includes(EFields.maidenName) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.maidenName}
                  fieldData={fields.maidenName}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.maidenName,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.zemisNumber) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.zemisNumber}
                  fieldData={fields.zemisNumber}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.zemisNumber,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.dateOfEntry) && (
                <FieldComparisonDatePicker
                  zones={zones}
                  fieldKey={EFields.dateOfEntry}
                  fieldData={fields.dateOfEntry}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.dateOfEntry,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.overallRestriction) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.overallRestriction}
                  fieldData={fields.overallRestriction}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.overallRestriction,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.marriedName) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.marriedName}
                  fieldData={fields.marriedName}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.marriedName,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.kantonReferenceNumber) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.kantonReferenceNumber}
                  fieldData={
                    fields.kantonReferenceNumber
                  }
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.kantonReferenceNumber,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.personalIdentificationNumber) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.personalIdentificationNumber}
                  fieldData={
                    fields.personalIdentificationNumber
                  }
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.personalIdentificationNumber,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.institutionNumber) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.institutionNumber}
                  fieldData={fields.institutionNumber}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.institutionNumber,
                      updatedFieldData
                    )
                  }
                />
              )}
              {fieldNames.includes(EFields.drivingCategories) && (
                <FieldComparisonInput
                  type='text'
                  zones={zones}
                  fieldKey={EFields.drivingCategories}
                  fieldData={fields.drivingCategories}
                  setPersonalInfo={(updatedFieldData: TFieldData) =>
                    handleFieldChange(
                      EFields.drivingCategories,
                      updatedFieldData
                    )
                  }
                />
              )}
            </div>
          </div>
          {fieldNames.includes(EFields.drivingCategory) && <DrivingField />}
        </>
      )}
    </div>
  );
};

export default DataVerification;

import moment from 'moment';

import { EUnits } from 'enums';
import { ICoordinates, IGenericProps } from 'types';

export const getImageFromBase64 = (image: string) => `data:image/jpg;base64, ${image}`;

export const formatDate = (date: Date, format = 'YYYY-MM-DD') => {
  let formattedDate = '';
  if (date) {
    formattedDate = moment(date).format(format);
  }
  return formattedDate;
};

export const getUrlParams = () => {
  const urlParams: IGenericProps = {};
  const params = (new URL((window as any).location)).searchParams;
  params.forEach((value: string, key: string) => {
    urlParams[key] = value;
  });
  return urlParams;
};

export const downloadFile = (content: string) => {
  const date = moment().format('YYYYMMDD_HHmm');
  const blob = new Blob([content], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `${date}_Reviewed_Transactions.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const convertCoordinatesToPixels = (coordinates: ICoordinates, image: {
  naturalWidth: number;
  naturalHeight: number;
}) => {
  switch (coordinates.unit) {
  case EUnits.percentage:
    return {
      x: (image.naturalWidth * coordinates.x) / 100,
      y: (image.naturalHeight * coordinates.y) / 100,
      width: (image.naturalWidth * coordinates.width) / 100,
      height: (image.naturalHeight * coordinates.height) / 100,
    }
  default:
    return coordinates;
  }
};

import { combineReducers, createStore } from 'redux';
import { enableBatching } from 'redux-batched-actions';

import {
  clearLocalStoragePartly,
  clearStorageData,
  removeStorageDataByKey,
} from 'util/storageHelper';
import { agentReducer } from 'store/features/agentManagement/reducer';
import { commonReducer } from 'store/features/common/reducer';
import { reviewResultReducer } from 'store/features/reviewResult/reducer';
import { rootActions } from 'store/types';
import { LOCAL_STORAGE_LNG } from 'constants/Static';

export const appReducer = combineReducers({
  common: commonReducer,
  agent: agentReducer,
  reviewResult: reviewResultReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === rootActions.reset) {
    clearStorageData();
    clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
    return appReducer(undefined, action);
  }

  if (action.type === rootActions.resetSession) {
    removeStorageDataByKey('state');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default createStore(
  enableBatching(rootReducer),
  (process.env?.NODE_ENV === 'development')
  && (window as any).__REDUX_DEVTOOLS_EXTENSION__
  && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

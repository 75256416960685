const config = {
  closed: [
    {
      Header: 'dashboard.header',
      columns: [
        {
          Header: 'dashboard.reviewed-tab.timestamp1.label',
          accessor: 'completedTime',
        },
        {
          Header: 'dashboard.reviewed-tab.tc.label',
          accessor: 'transactionCode',
        },
        {
          Header: 'dashboard.reviewed-tab.status.label',
          accessor: 'transactionState',
        },
        {
          Header: 'dashboard.reviewed-tab.timestamp2.label',
          accessor: 'reviewedTime',
        },
        {
          Header: 'dashboard.reviewed-tab.reviewer.label',
          accessor: 'reviewedBy',
        },
        {
          Header: 'dashboard.reviewed-tab.comments.label',
          accessor: 'comments',
        },
      ],
    },
  ],
  active: [
    {
      Header: 'dashboard.header',
      columns: [
        {
          Header: 'dashboard.open-tab.timestamp.label',
          accessor: 'completedTime',
        },
        {
          Header: 'dashboard.open-tab.tc.label',
          accessor: 'transactionCode',
        },
        {
          Header: 'dashboard.open-tab.oi.label',
          accessor: 'orderId',
        },
        {
          Header: 'dashboard.open-tab.status.label',
          accessor: 'transactionState',
        },
      ],
    },
  ],
  switchDataset: [
    {
      Header: 'account-switch.table.brand.label',
      accessor: 'name',
    },
    {
      Header: 'account-switch.table.app.label',
      accessor: 'title',
    },
    {
      Header: '',
      accessor: 'switchTo',
    },
  ],
};

export default config;

import React from 'react';

import GroupButtons from 'components/buttonTypes/GroupButtons';
import SingleButton from 'components/buttonTypes/SingleButton';
import { BUTTON_STYLE, BUTTON_TYPE } from 'constants/Static';

const ButtonTypes = (props: any) => {
  const {
    styled,
    answers,
    disabled,
    handleClick,
    activeIndexes,
    isLoading,
  } = props;

  let groupButtons: Array<any> = [];
  const answersData: Array<any> = [];

  answers?.forEach((button: any, index: number) => {
    if (button.buttonType === BUTTON_TYPE.SELECT_OPTION) {
      answersData.push({ [BUTTON_TYPE.SELECT_OPTION]: button });
    }
    if (button.buttonType === BUTTON_TYPE.RADIO_BUTTON) {
      groupButtons.push(button);
    }
    if (button.buttonType !== BUTTON_TYPE.RADIO_BUTTON || index === answers.length - 1) {
      groupButtons.length && answersData.push({ [BUTTON_TYPE.RADIO_BUTTON]: groupButtons });
      groupButtons = [];
    }
    switch (button.buttonType) {
    case BUTTON_TYPE.OPTION_BUTTON:
      answersData.push({ [BUTTON_TYPE.OPTION_BUTTON]: button });
      break;
    case BUTTON_TYPE.CHECKBOX:
      answersData.push({ [BUTTON_TYPE.OPTION_BUTTON]: button });
      break;
    }
  });

  const answersTexts = answers?.reduce((acc: string[], item: any, index: number) => {
    if (activeIndexes && activeIndexes.includes(index)) {
      acc.push(item.key);
    }
    return acc;
  }, []);

  if (!answers || !answers.length) return null;

  const randomKey = Math.floor(Math.random() * 100) + 2 + '' + new Date()
    .getTime() + Math.floor(Math.random() * 100) + 2 + (Math.random()
    .toString(36).replace(/[^a-zA-Z]+/g, '')
    .substr(0, 10));

  return <div className="footer-buttons-block">
    {
      answersData.map((itemData: any, index: number) => {
        switch (Object.keys(itemData)[0]) {
        case BUTTON_TYPE.OPTION_BUTTON:
          return (
            <SingleButton
              key={`singleButton_${itemData[BUTTON_TYPE.OPTION_BUTTON].text} ${randomKey}`}
              onClick={handleClick}
              buttonData={itemData[BUTTON_TYPE.OPTION_BUTTON]}
              variant={styled ? styled : BUTTON_STYLE.PRIMARY}
              radiusButton='circle-button'
              isDisabled={disabled && disabled.includes(index)}
              isActive={answersTexts.includes(itemData[BUTTON_TYPE.OPTION_BUTTON].key)}
              isLoading={isLoading}
            />);
        case BUTTON_TYPE.RADIO_BUTTON:
        {
          const activeItemsList: number[] = [];
          itemData[BUTTON_TYPE.RADIO_BUTTON].forEach((item: any, itemIndex: number) => {
            if (answersTexts.includes(item.key)) {
              activeItemsList.push(itemIndex);
            }
          });
          return (
            <React.Fragment key={`groupButtons${itemData[BUTTON_TYPE.RADIO_BUTTON].text}`}>
              <GroupButtons
                key={`groupButtons${itemData[BUTTON_TYPE.RADIO_BUTTON].key}`}
                listButtons={itemData[BUTTON_TYPE.RADIO_BUTTON]}
                onClick={handleClick}
                variant={BUTTON_STYLE.OUTLINE_SECONDARY}
                type={BUTTON_TYPE.RADIO}
                activeValues={activeItemsList}
              />
            </React.Fragment>
          );
        }
        default: {
          return <></>
        }
        }
      })
    }
  </div>;
};

export default ButtonTypes;

import React from 'react';

import AccordionMenu from 'components/accordionMenu/AccordionMenu';

const LeftMenu = (props: { pathName: string }) => {
  return (
    <div className="left-menu-container">
      <AccordionMenu />
    </div>
  );
};

export default LeftMenu;

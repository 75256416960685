import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  TAcceptanceChecks,
  TDocumentValidationReview,
  TIdentityReview,
  TManualCheckResult,
  TRejectionReasons,
  TRequiredFields
} from 'store/features/reviewResult/types';
import { EAnswers, EReviewStates, ERoutePaths, EWorkflowModules, EZones } from 'enums';
import { IRootState } from 'store/types';
import { CompleteTransactionAPI } from 'helper/api/route';
import Loader from 'components/loader/Loader';
import { getTransactionReviewResult } from 'helper';
import { useNavigate } from 'react-router-dom';

type TResultList = {
  title: string;
  result: string;
  resultKey: string;
}

const generateCheckResult = (manualCheckResult: TManualCheckResult) => {
  const data: TResultList[] = [];
  if (manualCheckResult.identityReview.documentValidationReview[0].result) {
    const { result } = manualCheckResult.identityReview.documentValidationReview[0];
    data.push({
      result,
      title: 'footer.check.doc.message',
      resultKey: `review-footer.answer-${result}.title`,
    })
  }
  if (manualCheckResult.identityReview.faceVerificationReview.result) {
    const { result } = manualCheckResult.identityReview.faceVerificationReview;
    data.push({
      result,
      title: 'footer.check.face.message',
      resultKey: `review-footer.answer-${result}.title`,
    })
  }
  if (manualCheckResult.identityReview.livenessDetectionReview.result) {
    const { result } = manualCheckResult.identityReview.livenessDetectionReview;
    data.push({
      result,
      title: 'footer.check.liveness.message',
      resultKey: `review-footer.answer-${result}.title`,
    })
  }
  if (manualCheckResult.a4ScanReview.result) {
    const { result } = manualCheckResult.a4ScanReview;
    data.push({
      result,
      title: 'footer.check.address.message',
      resultKey: `review-footer.answer-${result}.title`,
    })
  }
  if (manualCheckResult.a4ScanReview.result) {
    const { result } = manualCheckResult.a4ScanReview;
    data.push({
      result,
      title: 'footer.check.a4.message',
      resultKey: `review-footer.answer-${result}.title`,
    })
  }
  return data;
}

const acceptanceCheck = (
  t: Function, modules: string[], identityReview?: TIdentityReview, acceptanceChecks?: TAcceptanceChecks,
) => {
  const { documentValidationReview, livenessDetectionReview, faceVerificationReview } = identityReview || {};
  const {
    documentCountry,
    documentType,
    extraDetail,
    dataVerification,
    extraDetails,
  } = documentValidationReview?.[0] || {};
  const { fieldNames, zones, fields } = dataVerification || {};
  const {
    documentValid,
    documentPresented,
    documentPhysicalSigns,
    documentClearlyVisible,
    faceVerificationSamePerson,
    faceVerificationPhysicalSigns,
    livenessRealVideo,
  } = acceptanceChecks || {};

  const errorMessages = [];
  if(!documentCountry) errorMessages.push(`${t('identity.doc.country.label')} ${t('accept-modal.missing.message')}`)
  if(!documentType) errorMessages.push(`${t('identity.doc.type.label')} ${t('accept-modal.missing.message')}`)
  if(!!extraDetails?.length && !extraDetail) {
    errorMessages.push(`${t('identity.doc.subtype.label')} ${t('accept-modal.missing.message')}`)
  }
  if(fieldNames && fields) {
    fieldNames.forEach((field: string) => {
      const fieldData = fields[field as keyof TRequiredFields];
      zones?.forEach((zone: EZones) => {
        const { value, optional, disabled, editable } = fieldData?.[zone] || {};
        if (!optional && !value && !disabled && editable) {
          errorMessages.push(
            `${t(`identity.doc.data.${field}`)} (${t(`identity.doc.data.${zone}`)})
            ${t('accept-modal.missing.message')}`
          );
        }
      });
    })
  }
  if(documentValidationReview?.[0].result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.doc.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(modules.includes(EWorkflowModules.faceVerification) && faceVerificationReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.fv.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(modules.includes(EWorkflowModules.livenessDetection) && livenessDetectionReview?.result !== EAnswers.yes) {
    errorMessages.push(`${t('identity.ld.heading')} ${t('accept-modal-answer.message')}`);
  }
  if(documentValidationReview?.[0].result === EAnswers.yes &&
    [documentValid, documentPresented, documentPhysicalSigns, documentClearlyVisible].some((value) => !value)
  ) {
    errorMessages.push(`${t('identity.doc.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  if(modules.includes(EWorkflowModules.faceVerification) && faceVerificationReview?.result === EAnswers.yes &&
    [faceVerificationSamePerson, faceVerificationPhysicalSigns].some((value) => !value)
  ) {
    errorMessages.push(`${t('identity.fv.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  if(modules.includes(EWorkflowModules.livenessDetection)
    && livenessDetectionReview?.result === EAnswers.yes && !livenessRealVideo
  ) {
    errorMessages.push(`${t('identity.ld.heading')} ${t('accept-modal.checkboxes.message')}`);
  }
  return errorMessages;
}

const rejectionCheck = (
  t: Function, modules: string[], identityReview?: TIdentityReview, rejectionReasons?: TRejectionReasons,
) => {
  const { documentValidationReview, livenessDetectionReview, faceVerificationReview } = identityReview || {};
  const {imageQuality, document, documentRecording, faceVerification, livenessDetection, } = rejectionReasons || {};
  const errorMessages = [];
  const reviewResults = [documentValidationReview?.[0]];
  if (modules.includes(EWorkflowModules.faceVerification)) {
    reviewResults.push(faceVerificationReview);
  }
  if (modules.includes(EWorkflowModules.livenessDetection)) {
    reviewResults.push(livenessDetectionReview);
  }
  if (reviewResults.every((review: TDocumentValidationReview | undefined) => review?.result !== EAnswers.no)) {
    errorMessages.push(t('accept-modal-rejection.message'));
  }
  if(documentValidationReview?.[0].result === EAnswers.no &&
    [imageQuality, document, documentRecording].every((response) => !response?.length)
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if(modules.includes(EWorkflowModules.faceVerification)
    && faceVerificationReview?.result === EAnswers.no && !faceVerification?.length
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  if(modules.includes(EWorkflowModules.livenessDetection)
    && livenessDetectionReview?.result === EAnswers.no && !livenessDetection?.length
  ) {
    errorMessages.push(t('accept-modal.rejection-reason.message'));
  }
  return errorMessages;
}

const Footer = (props: {
  setShow: (value: boolean) => void;
  handleAnswerChange: (value: string[]) => void;
  setResponseError: (value: boolean) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);
  const state = useSelector((rootState: IRootState) => rootState);
  const { email = '' } = state.agent?.info || {};
  const { transactionCode, transactionData, manualCheckResult } = state.reviewResult;
  const { identityReview, acceptanceChecks, rejectionReasons } = manualCheckResult;
  const checkResult: TResultList[] = generateCheckResult(manualCheckResult);
  const [postProcessing, setPostProcessing] = useState(false);
  const { setShow, handleAnswerChange, setResponseError } = props;

  const complete = (reviewResult: EReviewStates) => {
    const errorMessages = [];
    if(reviewResult === EReviewStates.ACCEPTED) {
      errorMessages.push(...acceptanceCheck(t, transactionData?.modules || [], identityReview, acceptanceChecks));
    }
    if(reviewResult === EReviewStates.REJECTED) {
      errorMessages.push(...rejectionCheck(t, transactionData?.modules || [], identityReview, rejectionReasons));
    }
    if (errorMessages.length) {
      setShow(true);
      handleAnswerChange(errorMessages)
      return;
    }
    const reviewedData = getTransactionReviewResult(manualCheckResult, transactionData?.modules || [], {
      email,
      reviewResult,
      postProcessing,
      message,
    });
    setLoader(true);
    CompleteTransactionAPI(transactionCode, reviewedData).then(() => {
      setLoader(false);
      navigate(ERoutePaths.dashboard);
    }).catch(() => {
      setLoader(false);
      setShow(true);
      setResponseError(true);
      handleAnswerChange([t('footer.modal.fail.message')]);
    });
  };

  if (loader) {
    return <div className="footer-container"><div className="loader-container"> <Loader /></div></div>;
  }

  return (
    <div className="footer-container">
      <div className="manual-check">
        <h6>{t('footer.title')}</h6>
        {checkResult.map(({ result, resultKey, title }, index) => (
          <div className="check-item" key={index}>
            <span className={`check-mark ${result === EAnswers.yes ? 'tick' : 'cross'}`}>
              {result === EAnswers.yes ? '✔' : '✘'}
            </span>
            <span>{t(title)}</span>
            <span className={'result'}>
              {t(resultKey)}
            </span>
          </div>
        ))}
        <div className="post-processing">
          <div className="line"></div>
          <div>
            <input
              type="checkbox"
              checked={postProcessing}
              onChange={(event) => setPostProcessing(event.target.checked)}
            />
            <label htmlFor="postProcessing">{t('reject.post-processing.title')}</label>
          </div>
        </div>
      </div>
      <div className="summary">
        <h6>{t('footer.comment.input-title')}</h6>
        <textarea
          placeholder={t('footer.comment.placeholder')}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <div className="action-buttons">
          <button onClick={() => complete(EReviewStates.ACCEPTED)}>{t('footer.btn.accept')}</button>
          <button onClick={() => complete(EReviewStates.REJECTED)}>{t('footer.btn.reject')}</button>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import Cookies from 'js-cookie';
import moment from 'moment';

import { UnlockTransactionAPI } from 'helper/api/route';
import {
  clearLocalStoragePartly,
  getStorageData,
  removeStorageDataByKey,
} from 'util/storageHelper';
import { LOCAL_STORAGE_LNG } from 'constants/Static';
import { ERoutePaths } from 'enums';

export const logOut = async () => {
  const state: string = getStorageData('state') || '';
  const stateData = state ? JSON.parse(state) : {};
  const { transactionCode = '' } = stateData;

  if (transactionCode) UnlockTransactionAPI(transactionCode);
  removeStorageDataByKey('state');
  clearLocalStoragePartly([LOCAL_STORAGE_LNG]);
  const cookiesList: string[] = Object.keys(Cookies.get()) || [];
  cookiesList.forEach((cookie: string) => {
    if (cookie.startsWith('access_token')) Cookies.remove(cookie);
  });
  window.location.replace(ERoutePaths.root);
};

export const isAuthorized = () => Cookies.get('access_token');

export const getAuthHeader = () => {
  const accessToken: string = Cookies.get('access_token') || '';
  if (accessToken) {
    return accessToken;
  }
  return '';
};

export const getURLHashParams = () => {
  const hash: string = window.location.hash.substr(1);
  const params = hash.split('&').reduce((res: any, item: string) => {
    const parts = item.split('=');
    res[parts[0]] = parts[1];
    return res;
  }, {});
  return params;
};

export const ensureURLAuthorization = (urlHashParams: any) => {
  const { access_token: accessToken = '', expires_in: expiresIn } = urlHashParams;
  const expires = expiresIn ? moment(new Date()).add(expiresIn, 's').toDate() : undefined;
  // Temporary solution to handle case when token is greater than 4kb
  // which is supported by most of browser to store the cookies
  // in case of everything works fine this logic should be refactored to keep tokens in db
  // and use sessions to access them
  const LENGTHLIMIT = 4000;
  if (accessToken.length > LENGTHLIMIT) {
    const chunks: string[] = accessToken.match(new RegExp(`.{1,${LENGTHLIMIT}}`, 'g'));
    chunks.forEach((item: string, index: number) => {
      if (index === 0) {
        Cookies.set('access_token', item, { expires, secure: true });
      } else {
        Cookies.set(`access_token${index + 1}`, item, { expires, secure: true });
      }
    });
  } else {
    Cookies.set('access_token', accessToken, { expires, secure: true });
  }
  // Remove hash parameters
  window.location.hash = '';
};

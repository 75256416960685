import React from 'react';
import { useTranslation } from 'react-i18next';

import ListFromResources from 'components/accordionMenu/ListFromResources';

const Authoritative = () => {
  const { t } = useTranslation();

  return (
    <ListFromResources resource={[
      [
        {
          text: t('review-header.help.message1'),
          type: 'link',
          url: 'https://www.consilium.europa.eu/prado/en/search-by-document-country.html'
        },
        ` ${t('review-header.help.message2')}`,
      ],
      [
        {
          text: t('review-header.help.message3'),
          type: 'link',
          url: 'https://www.edisontd.nl/country-browser'
        },
        ` ${t('review-header.help.message4')}`,
      ],
    ]} />
  );
};

export default Authoritative;
